import { getEnv } from "lib/config"
import Image from "next/legacy/image"
import { isMobile, isDesktop } from "react-device-detect"

export interface ImageProps {
  src: string
  alt?: string
  width?: number
  height?: number
  quality?: number
  objectFit?: "fill" | "contain" | "none" | "scale-down" | "cover"
  objectPosition?: string
  roundedSize?: "small" | "medium" | "large" | "extra-large" | "ultra-large"
  rounded?: boolean
  roundedType?: "top" | "left" | "bottom" | "right" | "full"
  resource?: "asset" | "cloud"
  onClick?: () => void
  placeholder?: "blur" | "empty"
  priority?: boolean
  sizes?: string
  className?: string
}

function ImageComponent({
  src,
  alt,
  roundedSize,
  quality = 65,
  width,
  height,
  objectFit,
  objectPosition,
  rounded,
  roundedType,
  resource,
  priority,
  onClick,
  placeholder = "empty",
  sizes = "(max-width: 768px) 100vw, 90vw",
  className = "",
}: ImageProps) {
  let roundedImage = ""
  let priorityImage = isDesktop

  if (resource === "cloud") {
    const generateURL = `${getEnv("NEXT_PUBLIC_IMAGE_ASSET_URL")}/w_${width || 250},h_${
      height || 250
    },c_fill/${getEnv("NEXT_PUBLIC_CLOUD_IMAGE_ID")}/${src}`

    return (
      <Image
        alt={alt}
        src={generateURL}
        layout="fill"
        priority={isDesktop}
        loading={isMobile ? "lazy" : "eager"}
      />
    )
  }

  switch (roundedType) {
    case "top":
      roundedImage = "rounded-t"
      break
    case "left":
      roundedImage = "rounded-l"
      break
    case "right":
      roundedImage = "rounded-r"
      break
    case "bottom":
      roundedImage = "rounded-b"
      break
    default:
      roundedImage = "rounded"
      break
  }

  switch (roundedSize) {
    case "small":
      roundedImage = roundedType ? `${roundedImage}-sm` : " rounded-sm"
      break
    case "large":
      roundedImage = roundedType ? `${roundedImage}-lg` : " rounded-lg"
      break
    case "extra-large":
      roundedImage = roundedType ? `${roundedImage}-xl` : "rounded-xl"
      break
    case "ultra-large":
      roundedImage = roundedType ? `${roundedImage}-2xl` : "rounded-2xl"
      break
    default:
      break
  }

  if (priority) {
    priorityImage = priority
  }

  if (width && height) {
    return (
      <Image
        src={src}
        alt={alt}
        objectFit={objectFit}
        quality={quality}
        width={width}
        height={height}
        objectPosition={objectPosition}
        className={`${className} ${rounded ? roundedImage : ""}`}
        priority={priorityImage}
        onClick={onClick}
        blurDataURL={src}
        placeholder={placeholder}
        loading={isMobile ? "lazy" : "eager"}
      />
    )
  }

  return (
    <Image
      src={src}
      alt={alt}
      objectFit={objectFit}
      quality={quality}
      layout="fill"
      objectPosition={objectPosition}
      className={`${className} ${rounded ? roundedImage : ""}`}
      priority={priorityImage}
      onClick={onClick}
      blurDataURL={src}
      placeholder={placeholder}
      loading={isMobile ? "lazy" : "eager"}
      sizes={sizes}
    />
  )
}

export default ImageComponent
