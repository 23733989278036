import { useContext, useEffect, useState } from "react"
import Link from "next/link"
import Icons from "lib/components/Icons"
import Text from "lib/components/Text"
import { useRouter } from "next/router"
import { LanguageContext } from "context/LanguageContext"
import { handleChar } from "lib/helpers"

export interface itemNavigationSidebar {
  url?: string
  name: string
  icon?: string
  tourName?: string
  iconOnly?: boolean
  withIcon?: boolean
  childItem?: {
    name: string
    url: string
  }[]
  toggleDrawer,
  defaultShow?: boolean
}

interface WrapperItemSidebarProps {
  children: React.ReactNode
  anyChildItem: boolean
  url: string
  pathName: string
  showChildren: boolean
  setShowChildren: () => void
  dataChildren: any
  toggleDrawer,
}

function NavigationChildrenSidebar({ dataChildren, pathName, showChildren, toggleDrawer }) {
  let heightChildren = ""

  const scrolltoHash = (element_id: string) => {
    const element = document.getElementById(element_id)
    const headerOffset = 55
    const elementPosition = element?.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset
    localStorage.setItem("activeServices", element_id)
    const storageEvent = new Event("storage")
    window.dispatchEvent(storageEvent)
  
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    })
  }

  if (dataChildren.length === 4) {
    heightChildren = "h-64"
  }
  return (
    <div
      className={`w-full transition-height duration-300 transform ${
        showChildren ? `visible ${heightChildren}` : "invisible h-0"
      }`}>
      {dataChildren.map((itemChildren, indexItemChildren) => (
        <div onClick={() => toggleDrawer()} className="my-4" key={indexItemChildren} role="button" tabIndex={indexItemChildren}>
          <Link href={itemChildren.url} legacyBehavior>
            <a>
              <div
                className={`hover:text-primary py-3 p-3 rounded-md ${
                  itemChildren.url === pathName
                    ? "bg-primary text-white hover:bg-transparent"
                    : "text-default"
                }`}>
                <div className="relative flex">
                  <Text>
                    {itemChildren.name}
                  </Text>
                  {itemChildren.badge && (
                    <div className="-ml-1 inline-flex justify-center items-center w-2.5 h-2.5 text-xs font-bold text-white bg-red-500 rounded-full" />
                  )}
                </div>
              </div>
            </a>
          </Link>
        </div>
      ))}
    </div>
  )
}

function WrapperItemSidebar({
  children,
  anyChildItem = false,
  url = "#",
  pathName = "/",
  showChildren = false,
  dataChildren = [],
  setShowChildren,
  toggleDrawer,
}: WrapperItemSidebarProps) {
  if (anyChildItem) {
    return (
      <>
        <div
          aria-label="item"
          role="button"
          tabIndex={0}
          className="w-full"
          onClick={setShowChildren}>
          {children}
        </div>

        <NavigationChildrenSidebar
          dataChildren={dataChildren}
          showChildren={showChildren}
          pathName={pathName}
          toggleDrawer={toggleDrawer}
        />
      </>
    )
  }

  return (
    <Link href={url} legacyBehavior>
      <a className="w-full">{children}</a>
    </Link>
  )
}

function NavigationItemSidebar({ url, icon, name, tourName, iconOnly = false, childItem, withIcon = true, toggleDrawer, defaultShow = false }: itemNavigationSidebar) {
  const { useTranslation: t } = useContext(LanguageContext)
  const router = useRouter()
  const [showChildren, setShowChildren] = useState(defaultShow)
  let isActiveItem = false

  useEffect(() => {
    const itemChildren = childItem.find((item) => item.url === router.pathname)
    if (itemChildren && itemChildren.url) {
      setShowChildren(true)
    }
  }, [childItem, router])

  if (router.pathname === url) {
    isActiveItem = true
  }

  return (
    <div className="relative flex items-center flex-col">
      <WrapperItemSidebar
        anyChildItem={childItem && childItem.length > 0}
        url={url}
        showChildren={showChildren}
        dataChildren={childItem}
        pathName={router.pathname}
        toggleDrawer={toggleDrawer}
        setShowChildren={() => {
          setShowChildren((currentItem) => !currentItem)
        }}>
        <div
          className={`hover:text-primary py-3 sm:mx-4 rounded-md navItem ${tourName} ${
            isActiveItem
              ? "bg-primary text-white hover:bg-transparent"
              : "text-default"
          }`}>
          <div className="flex relative items-center">
            {withIcon &&
              <div className="mr-5">
                <Icons iconName={icon} />
              </div>
            }
            {!iconOnly && (
              <div>
                <Text>
                  {t(`${handleChar({ value: name, replacement: "_" }).toLowerCase()}`)}
                </Text>
              </div>
            )}
            {childItem && childItem.length > 0 && (
              <div className="absolute right-0">
                {!iconOnly && (
                  <Icons
                    iconName="arrow-right"
                    width="24"
                    height="24"
                    styles={`transform ${
                      showChildren ? "rotate-90 duration-500" : "rotate-0 duration-500"
                    }`}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </WrapperItemSidebar>
    </div>
  )
}

NavigationItemSidebar.defaultProps = {
  childItem: [],
}

export default NavigationItemSidebar
