/* eslint-disable no-plusplus */
import withSession from "lib/session"
import moment from "moment-timezone"
import slugify from "slugify"
import sha256 from "crypto-js/sha256"
import { isMobile } from "react-device-detect"
import consola from "consola"
import { useMemo } from "react"

type ColorTypeStatus = {
  name: string;
  minScore: number;
  maxScore: number;
  color: string;
};

export const capitalize = (str, separator = " ") => {
  if (str) {
    const splitStr = str.toLowerCase().split(separator)
    for (let i = 0; i < splitStr.length; i += 1) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(" ")
  }
  return "-"
}

export const parseFilter = (currentQuery: any) => {
  if (!currentQuery) {
    return ""
  }

  const finalURL = Object.keys(currentQuery)
    .map((key) => {
      if (currentQuery[key]) {
        return `${key}=${currentQuery[key]}`
      }
      return null
    })
    .filter(Boolean)
    .join("&")

  return `?${finalURL}`
}

export const formatValue = (value) =>
  `${value ? value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") : 0}`

export const groupBy = function (array, key) {
  if (!array || array.length < 1) {
    return []
  }

  return array.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, [])
}

export const findValueArray = function (array, key, value) {
  return array.find((item) => item[key] === value) || false
}

export const formatStringToDateFormat = (
  value,
  format = "default",
  substract = {
    type: null,
    total: 0,
  },
) => {
  moment.tz.setDefault("Asia/Jakarta")
  if (substract.type) {
    return moment(value).subtract(substract.total, substract.type).format(format)
  }
  if (value) {
    if (format === "default") {
      return moment(value).format(moment.defaultFormatUtc)
    }
    return moment(value).tz("Asia/Jakarta").format(format)
  }
  return "-"
}

export const withAuthInfo = ({ redirectToLogin = true }: { redirectToLogin?: boolean, resolvedUrl?: string }) =>
  withSession(async ({ req }) => {
    try {
      const userinfo = req.session.get("userinfo")

      if (!userinfo.user_id) throw new Error("unauthorized")

      return {
        props: {
          userinfo,
        },
      }
    } catch (err) {
      if (redirectToLogin) {
        const redirectPath = `/?r=${encodeURIComponent(req.url)}`
        return {
          redirect: {
            permanent: false,
            destination: redirectPath,
          },
        }
      }
      return {
        props: {},
      }
    }
  })

export const formatToIDR = (number: number) =>
  new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(number)

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const cutString = (text: string, length = 80) => {
  const subStringText = text?.substring(0, length)
  if (subStringText !== " " && text?.length >= length) {
    const splitText = subStringText.split(" ")
    const spliceText = splitText.splice(0, splitText.length - 1)
    text = `${spliceText.join(" ")}...`
  }
  return text
}

export const roundValueRating = (value) => {
  if (value > 1 && value <= 1.5) {
    return 1.5
  }
  if (value > 2 && value <= 2.5) {
    return 2.5
  }
  if (value > 3 && value <= 3.5) {
    return 3.5
  }
  if (value > 4 && value <= 4.9) {
    return 4.5
  }

  return Math.ceil(value)
}

export const removeSpecialCharacter = (str, valToReplace = "") => {
  if (str) {
    const result = str.replace(/[^a-zA-Z ]/g, valToReplace)
    return result
  }
  return "-"
}

const setCharAt = (str, index, chr) => {
  if (index > str.length - 1) return str
  return str.substring(0, index) + chr + str.substring(index + 1)
}

export const closePartialUsername = (str) => {
  str = str?.split(" ").splice(0, 2).join(" ")
  const firstName = str?.split(" ")?.[0]?.length
  const lastName = str?.split(" ")?.[1]?.length

  for (let i = 2; i < firstName; i++) {
    str = setCharAt(str, i, "*")
  }

  for (let i = firstName + 1; i < lastName + firstName - 1; i++) {
    str = setCharAt(str, i, "*")
  }
  return str
}

export const shuffleArray = (array) => {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export const downloadUrlOpenTab = (url) => {
  if (isMobile) {
    window.location.href = url
  } else {
    const a = document.createElement("a")
    a.target = "_blank"
    a.href = url
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export const checkPermissionRedirect = (
  url = process.env.NEXT_PUBLIC_URL,
  onAlert = () => {
    // do nothing.
  },
) => {
  const popUp = window.open(url)
  if (popUp == null || typeof popUp === "undefined") {
    onAlert()
  } else {
    popUp.focus()
  }
}

interface PropsHandleChar {
  value: string
  replacement?: string
  remove?: RegExp
  trim?: boolean
}

export const handleChar = ({
  value = "",
  replacement = "-",
  remove = /[~!@=$%^[&{*}()_+-]/g,
  trim = false,
}: PropsHandleChar) => {
  value = value.replace(/[`$%&<>|_^]/gi, "")
  return slugify(value, {
    remove,
    replacement,
    trim,
  })
}

const defaultCallback = (url) => url
export const widgetCloudinary = (cb = defaultCallback, folderName = "client") => {
  const loadWindow: any = window
  loadWindow.widgetCloudinary = loadWindow.cloudinary.createUploadWidget(
    {
      cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_NAME,
      uploadPreset: process.env.NEXT_PUBLIC_CLOUDINARY_PRESET,
      folder: `${process.env.NEXT_PUBLIC_CLOUDINARY_FOLDER}/${folderName}`,

      autoMinimize: true,
      cropping: true,
      croppingShowDimensions: true,
      singleUploadAutoClose: true,
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        cb(result?.info?.url)
      }
    },
  )
}

export const convertDateGMT = (date: string) => {
  const getDate = new Date(date)
  return `${getDate.getFullYear()}-${getDate.getMonth() + 1}-${getDate.getDate()}`
}

export const calculateDateLast = (valueOfType, dateType, formatDate = "YYYY-MM-DD") =>
  moment().subtract(valueOfType, dateType).format(formatDate)

export function compareDateWithNow(date) {
  const dateCompared = moment(date || new Date())
  const dateNow = moment(new Date())
  return dateNow.diff(dateCompared, "days")
}

export const addDays = (value, day) => {
  if (value) {
    return value.getTime() + day * 24 * 60 * 60 * 1000
  }
  return new Date()
}

export const generateEncryptString = (firstString, encryptedString, lengthSubstring = 10) => {
  if (firstString && encryptedString) {
    const result = `${firstString}-${sha256(encryptedString)
      .toString()
      .substring(0, lengthSubstring)}`
    return result
  }
  return "-"
}

export const checkProgramTypeTraining = (duration, programType, type = "durations") => {
  if (type !== "times") {
    if (programType !== "bootcamp") {
      return duration > 1 ? "Days" : "Day"
    }
    return duration > 1 ? "Weeks" : "Week"
  }

  if (programType !== "bootcamp") {
    return duration > 1 ? "Hours" : "Hour"
  }
  return duration > 1 ? "Classes" : "Class"
}

export function checkStringContain(target, pattern) {
  let value = 0
  pattern.forEach((word) => {
    value += target.includes(word)
  })
  return value === 1
}

export function optimizeImageCloudinary(url, quality = "high", width = "w_360") {
  if (url) {
    let qualityImage = "q_100"

    switch (quality) {
      case "low":
        qualityImage = "q_auto:good"
        break
      case "medium":
        qualityImage = "q_80"
        break
      default:
        break
    }

    const imageUrl = url.split("/")
    imageUrl.splice(6, 0, width)
    imageUrl.splice(7, 0, qualityImage)
    const resultImage = imageUrl.join("/")
    return resultImage
  }
  return url
}

export function isDate(date) {
  return !Number.isNaN(new Date(date).getTime())
}

export function romanizeNumber(num) {
  const lookup = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1}; let roman = ""
  Object.keys(lookup).forEach(i => {
    while ( num >= lookup[i] ) {
      roman += i
      num -= lookup[i]
    }
  })
  return roman
}

export function generateTrainingDate(startDate, endDate) {
  let trainingDate = moment(startDate).format("DD MMM YYYY")
  
  if (!moment(startDate).isSame(moment(endDate), "day")) {
    trainingDate = `${moment(startDate).format("DD")} - ${moment(endDate).format("DD MMM YYYY")}`
  }

  if (!moment(startDate).isSame(moment(endDate), "month")) {
    trainingDate = `${moment(startDate).format("DD MMM")} - ${moment(endDate).format("DD MMM YYYY")}`
  }
  
  if (!moment(startDate).isSame(moment(endDate), "year")) {
    trainingDate = `${moment(startDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")}`
  }

  return trainingDate
}

export function hasUppercase(str) {
  return /[A-Z]/.test(str)
}

export function hasLowercase(str) {
  return /[a-z]/.test(str)
}

export function containsNumbers(str) {
  return /\d/.test(str)
}

export function containsSpecial(str) {
  const format = /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/
  return format.test(str)
}

export const colorTypeStatus: ColorTypeStatus[] = [
  {
    name: "Poor",
    minScore: 0,
    maxScore: 49,
    color: "bg-red-600",
  },
  {
    name: "Sub Standartd",
    minScore: 50,
    maxScore: 59,
    color: "bg-amber-200",
  },
  {
    name: "Fair",
    minScore: 60,
    maxScore: 69,
    color: "bg-green-300",
  },
  {
    name: "Good",
    minScore: 70,
    maxScore: 79,
    color: "bg-sky-500",
  },
  {
    name: "Very Good",
    minScore: 80,
    maxScore: 89,
    color: "bg-blue-500",
  },
  {
    name: "Excellent",
    minScore: 80,
    maxScore: 100,
    color: "bg-blue-700",
  },
]



export function getStatusByScore(score: number) {
  
  if (score < 0 || score > 100) {
    consola.error("Score is not valid. Score must be between 0 and 100.")
    return <div>-</div>
  }
  
  const statusScore = colorTypeStatus.find(status => score >= status.minScore && score <= status.maxScore)
  if (!statusScore) {
    consola.error("No status found for the given score.")
    return <div>-</div>
  }

  return (
    <div className="flex gap-2 items-center">
      ({`${score}`}) <div className={`${statusScore.color}`} style={{width: 10, height: 10 }} /> {`${statusScore.name}`}
    </div>
  )
}

export function chunkArray(array, totalChunk) {
  const result = []
  for (let i = 0; i < array.length; i += totalChunk) {
      result.push(array.slice(i, i + totalChunk))
  }
  return result
}

export const generateImageWebp = (url: string) => {
  if (typeof url !== "string" || !url) {
    return ""
  }

  if (url.includes(".png") || url.includes(".jpg") || url.includes(".jpeg")) {
    return url
      .replace("/upload", "/upload/f_webp")
      .replace("http://", "https://")
      .replace(/\.(png|jpg|jpeg)$/, ".webp")
  } 
    return url.replace("http://", "https://")
}