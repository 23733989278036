import SelectorLanguage from "@components/Selector/Language"
import Container from "lib/components/Container"
import Icons from "lib/components/Icons"
import Text from "lib/components/Text"
import { LanguageContext } from "context/LanguageContext"
import { gtmEvent } from "lib/gtm"
import Link from "next/link"
import { useContext } from "react"
import dynamic from "next/dynamic"

const Image = dynamic(() => import("lib/components/Image"), {
  ssr: false,
})

function Footer() {
  const { useTranslation: t } = useContext(LanguageContext)

  return (
    <div className="bg-primary pt-16 pb-28 lg:pb-16 px-2 sm:px-16 text-white">
      <Container>
        <div className="grid grid-cols-12 gap-6 md:gap-2">
          <div className="col-span-full md:col-span-2">
            <div className="grid grid-rows-1 gap-2">
              <div>
                <Link href="/" legacyBehavior>
                  <a className="block">
                    <Image
                      src="/assets/images/logo-large-secondary.png"
                      alt="Picture of the author"
                      width={148}
                      height={33}
                    />
                  </a>
                </Link>
              </div>
              <div className="flex gap-6 opacity-70">
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => gtmEvent("ce_follow_social", { social_media: "Facebook" })}>
                  <Link href="https://www.facebook.com/PasarTrainer/" legacyBehavior>
                    <a className="block" aria-label="Facebook">
                      <Icons iconName="facebook" width={20} height={20} />
                    </a>
                  </Link>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => gtmEvent("ce_follow_social", { social_media: "Twitter" })}>
                  <Link href="https://twitter.com/pasartrainer" legacyBehavior>
                    <a className="block" aria-label="Twitter">
                      <Icons iconName="twitter" width={20} height={20} />
                    </a>
                  </Link>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => gtmEvent("ce_follow_social", { social_media: "Linkedin" })}>
                  <Link href="https://www.linkedin.com/company/pasartrainer/" legacyBehavior>
                    <a className="block" aria-label="Linkedin">
                      <Icons iconName="linkedin" width={20} height={20} />
                    </a>
                  </Link>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => gtmEvent("ce_follow_social", { social_media: "Instagram" })}>
                  <Link href="https://www.instagram.com/pasartrainer/" legacyBehavior>
                    <a className="block" aria-label="Instagram">
                      <Icons iconName="instagram" width={20} height={20} />
                    </a>
                  </Link>
                </div>
              </div>
              <SelectorLanguage />
            </div>
          </div>
          <div className="col-span-full md:col-span-1" />
          <div className="col-span-full md:col-span-2">
            <div className="mb-4">
              <Text weight="bold" size="large">
                {t("partnership")}
              </Text>
            </div>
            <div className="flex flex-col gap-4">
              <div
                  className="opacity-70"
                  role="button"
                  tabIndex={0}
                  onClick={() => gtmEvent("ce_footer_menu_click", { menu_name: "Join as a Trainer" })}>
                  <Link passHref href="/trainer-form" legacyBehavior>
                    <a>{t("join_trainer")}</a>
                  </Link>
                </div>
              <div
                className="opacity-70"
                role="button"
                tabIndex={0}
                onClick={() => gtmEvent("ce_footer_menu_click", { menu_name: "Join as a Training Provider" })}>
                <Link passHref href="/provider-form" legacyBehavior>
                  <a>{t("join_provider")}</a>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span-full md:col-span-2">
            <div className="mb-4">
              <Text weight="bold" size="large">
                {t("services")}
              </Text>
            </div>
            <div className="flex flex-col gap-4">
              <div
                className="opacity-70"
                role="button"
                tabIndex={0}
                onClick={() => gtmEvent("ce_footer_menu_click", { menu_name: "For Business" })}>
                <Link passHref href="/business" legacyBehavior>
                  <a>{t("for_business")}</a>
                </Link>
              </div>
              <div
                className="opacity-70"
                role="button"
                tabIndex={0}
                onClick={() =>
                  gtmEvent("ce_footer_menu_click", { menu_name: "Check Certificate" })
                }>
                <Link passHref href="/certifications/validate" legacyBehavior>
                  <a>{t("check_certificate")}</a>
                </Link>
              </div>
              <div
                className="opacity-70"
                role="button"
                tabIndex={0}
                onClick={() =>
                  gtmEvent("ce_footer_menu_click", { menu_name: "Referral" })
                }>
                <Link passHref href="/referral" legacyBehavior>
                  <a>{t("referral")}</a>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span-full md:col-span-2">
            <div className="mb-4">
              <Text weight="bold" size="large">
                {t("company")}
              </Text>
            </div>
            <div className="flex flex-col gap-4">
              <div
                className="opacity-70"
                role="button"
                tabIndex={0}
                onClick={() => gtmEvent("ce_footer_menu_click", { menu_name: "About Us" })}>
                <Link passHref href="/about" legacyBehavior>
                  <a>{t("about_us")}</a>
                </Link>
              </div>
              <div
                className="opacity-70"
                role="button"
                tabIndex={0}
                onClick={() =>
                  gtmEvent("ce_footer_menu_click", { menu_name: "Terms & Conditions" })
                }>
                <Link passHref href="/terms-and-conditions" legacyBehavior>
                  <a>{t("terms_conditions")}</a>
                </Link>
              </div>
              <div
                className="opacity-70"
                role="button"
                tabIndex={0}
                onClick={() => gtmEvent("ce_footer_menu_click", { menu_name: "Blog" })}>
                <Link passHref href="/blog" legacyBehavior>
                  <a>Blog</a>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span-full md:col-span-3">
            <div className="mb-4">
              <Text weight="bold" size="large">
                {t("contact_us")}
              </Text>
            </div>
            <div className="flex flex-col gap-4">
              <div
                className="opacity-70 flex"
                role="button"
                tabIndex={0}
                onClick={() => gtmEvent("ce_footer_menu_click", { menu_name: "Email" })}>
                <a className="flex flex-row items-center gap-x-2" href="mailto:contact@pasartrainer.com">
                  <div>
                    <Icons iconName="mail" width={24} height={24} />
                  </div>
                  <Text>contact@pasartrainer.com</Text>
                </a>
              </div>

              <div
                className="opacity-70 flex"
                role="button"
                tabIndex={0}
                onClick={() => gtmEvent("ce_footer_menu_click", { menu_name: "Phone" })}>
                <a className="flex flex-row items-center gap-x-2" href="tel:+6222129277909">
                  <div>
                    <Icons iconName="phone" width={24} height={24} />
                  </div>
                  <Text>+6221-2927-7909</Text>
                </a>
              </div>

              <div
                className="opacity-70 flex"
                role="button"
                tabIndex={0}
                onClick={() => gtmEvent("ce_footer_menu_click", { menu_name: "WhatsApp" })}>
                <a className="flex flex-row items-center gap-x-2" href="https://wa.me/6282310261558">
                  <div>
                    <Icons iconName="whatsapp" width={24} height={24} />
                  </div>
                  <Text>082310261558</Text>
                </a>
              </div>

              <div
                className="opacity-70 flex"
                role="button"
                tabIndex={0}
                onClick={() => gtmEvent("ce_footer_menu_click", { menu_name: "Address" })}>
                <a
                  className="flex flex-row gap-x-2"
                  href="https://www.google.com/maps/place/Equity+Tower/@-6.2263898,106.8065775,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69f1505affffff:0x301b8a0f5d2c33ed!8m2!3d-6.2263889!4d106.8087453">
                  <div>
                    <Icons iconName="location" width={24} height={24} />
                  </div>
                  <Text>
                    PT Pasar Jasa Profesional Equity Tower 37th Floor Unit D & H, SCBD Lot. 9
                  </Text>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer