import { LanguageContext } from "context/LanguageContext"
import { useContext, useEffect, useState } from "react"

export type LanguageType = "ID" | "EN"

const useSelectorLanguage = () => {
  const [language, setLanguage] = useState<LanguageType>("EN")
  const { handleChangeLanguage, language: selectedLanguage } = useContext(LanguageContext)

  useEffect(() => {
    if (selectedLanguage) {
      if (selectedLanguage === "ID") {
        setLanguage("ID")
      } else {
        setLanguage("EN")
      }
    } else {
      setLanguage("EN")
    }
  }, [selectedLanguage])
  
  return {
    language,
    setLanguage,
    handleChangeLanguage,
    selectedLanguage,
  }
}

export default useSelectorLanguage
