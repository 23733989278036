import { useAuth } from "lib/auth"
import { useContext, useEffect, useState } from "react"
import jwtDecode from "jwt-decode"
import { toast } from "react-toastify"
import { useCheckout } from "lib/checkout"
import consola from "consola"
import { gtmEvent } from "lib/gtm"
import fetcher from "lib/fetcher"
import { captureException } from "@sentry/react"
import { checkStringContain } from "lib/helpers"
import { LanguageContext, LanguageType } from "context/LanguageContext"
import { useRouter } from "next/router"
import DefaultLayout from "./DefaultLayout"
import AdminLayout from "./AdminLayout"
import OnBoardingLayout from "./OnboardingLayout"
import FeedbackLayout from "./FeedbackLayout"
import CheckoutLayout from "./CheckoutLayout"
import TransparentLayouts from "./TransparentLayout"
import PrintLayout from "./PrintLayout"
import { routeHomepage } from "./Header"
import BlogLayout from "./BlogLayout"
import NoLayout from "./NoLayout"

const layouts = {
  default: DefaultLayout,
  admin: AdminLayout,
  onboarding: OnBoardingLayout,
  feedback: FeedbackLayout,
  checkout: CheckoutLayout,
  transparent: TransparentLayouts,
  print: PrintLayout,
  blog: BlogLayout,
  no: NoLayout,
}

export interface Token {
  email: string
  name: string
  credential: string
}

function LayoutWrapper(props: any) {
  const { children } = props
  const {
    isLoggedIn,
    handleUserLogin,
    handleCheckEmail,
    handleUserRegister,
    handleUserLogout,
  } = useAuth()
  const { removeCheckoutData } = useCheckout()
  const [isClientSide, setIsClientSide] = useState<boolean>(false)
  const { useTranslation: t } = useContext(LanguageContext)
  const router = useRouter()

  const handleLoginWithGoogle = async (params, redirectPath, classID, orderID, type) => {
    try {
      if (classID) {
        await handleCheckEmail({ email: params.username, class_id: classID, is_google: true })
      }
      const userLogin = await handleUserLogin(params)

      if (userLogin) {
        localStorage.setItem("userdata", JSON.stringify(userLogin))
        localStorage.setItem(
          "qismo-widget",
          JSON.stringify({
            unique_id: userLogin?.email,
            display_name: userLogin?.first_name,
            extra_fields: JSON.stringify([]),
          }),
        )
        gtmEvent("ce_login", {
          user_id: userLogin.user_id,
          custom_user_id: userLogin.user_id,
          account_type: userLogin.user_role !== "ptc-user" ? "Company" : "Individual",
          job_title: userLogin.job_title,
          method: "Google",
        })
        if (orderID != null) {
          const getCheckout = localStorage.getItem("checkoutData")
          const dataCheckout = JSON.parse(getCheckout)
          const dataPayload = {
            class_id: dataCheckout.class_id,
            order_id: orderID,
            payment_method: dataCheckout.payment_method,
            coupon_code: dataCheckout.coupon_code,
          }
          await fetcher("/api/order/update", "POST", dataPayload)
          await removeCheckoutData()
          toast.success("Success Create Order")
          router.replace(`/checkout/finish?type=${type}&orderId=${orderID}`)
        } else {
          localStorage.setItem("active_route", "home")

          if (redirectPath?.includes("my-classes")) {
            localStorage.setItem("active_route", "my-classes")
          }

          if (redirectPath?.includes("orders")) {
            localStorage.setItem("active_route", "orders")
          }

          if (redirectPath?.includes("inhouse-proposal")) {
            localStorage.setItem("active_route", "inhouse-proposal")
          }

          window.location.href = redirectPath || "/homepage"
        }
      }
    } catch (error) {
      let message = error.response?.data.message
      if (error.response?.data.message === "ErrUserNotFound") {
        message = t("not_found_account")
      }
      toast.error(message || "Oops: Account not found")
    }
  }

  const handleRegisterWithGoogle = async ({ email, name, token }) => {
    try {
      const res = await handleUserRegister({
        email,
        firstName: name?.split(" ")[0] || "",
        lastName: name?.split(" ")[1] || "",
        token,
        jobTitle: "",
        credentialType: "google",
      })
      localStorage.setItem(
        "qismo-widget",
        JSON.stringify({
          unique_id: email,
          display_name: name,
          extra_fields: JSON.stringify([]),
        }),
      )
      localStorage.setItem(
        "userdata",
        JSON.stringify({
          email,
          first_name: name?.split(" ")[0] || "",
          last_name: name?.split(" ")[1] || "",
          is_verified: res.is_verified,
        }),
      )
      gtmEvent("ce_sign_up", {
        user_id: res.user_id,
        custom_user_id: res.user_id,
        account_type: "Individual",
        job_title: null,
        method: "Google",
      })
      toast.success("Yeay! Your Account is successfully created")
      window.location.href = "/homepage"
    } catch (err) {
      let messages = err.message
      if (err.message === "ErrUserAlreadyExist") {
        messages = "That email is taken. Try another."
      }
      toast.error(messages)
    }
  }

  useEffect(() => {
    async function handleCredentialResponse(response) {
      try {
        let redirectPath = window.location.pathname
        const { email, name } = jwtDecode<Token>(response.credential)
        const url_string = window.location.href
        const url = new URL(url_string)
        const classID = url.searchParams.get("classID")
        const orderID = url.searchParams.get("order_id")
        const type = url.searchParams.get("type")

        if (window.location.pathname === "/" || checkStringContain(redirectPath, routeHomepage)) {
          redirectPath = "/homepage"
        }

        if (url.searchParams.get("r")) {
          redirectPath = url.searchParams.get("r")
        }

        if (window.location.pathname !== "/register") {
          const params = { username: email, password: response.credential, loginType: "google" }
          await handleLoginWithGoogle(params, redirectPath, classID, orderID, type)
        } else {
          await handleRegisterWithGoogle({ email, name, token: response.credential })
        }
      } catch (error) {
        captureException(error.message)
      }
    }

    try {
      const googleAccount = (window as any).google
      const qismoApp = localStorage.getItem("qismo-widget")

      if (!isLoggedIn && !qismoApp) {
        if (googleAccount) {
          googleAccount.accounts.id.initialize({
            client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
            callback: handleCredentialResponse,
            cancel_on_tap_outside: true,
            itp_support: true,
            context: window.location.pathname === "/register" ? "signup" : "signin",
            use_fedcm_for_prompt: true,
          })

          googleAccount.accounts.id.prompt()
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
  }, [isLoggedIn])

  useEffect(() => {
    setIsClientSide(true)
    return () => {
      setIsClientSide(false)
    }
  }, [])

  const Layout = layouts[children.type.layout]

  const handleLogout = async () => {
    try {
      await handleUserLogout()
      removeCheckoutData()
      // use router reload to get fresh data after logout
      window.location.href = "/"
    } catch (err) {
      consola.error(err)
    }
  }
  if (!isClientSide && children.type.layout === "amp") {
    return children
  }
  if (Layout != null) {
    return (
      <Layout {...props} handleLogout={handleLogout}>
        {children}
      </Layout>
    )
  }

  return (
    <DefaultLayout {...props} handleLogout={handleLogout}>
      {children}
    </DefaultLayout>
  )
}

export default LayoutWrapper
