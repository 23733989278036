const DEFAULT_CANONICAL = process.env.NEXT_PUBLIC_URL
const DEFAULT_TITLE = "PasarTrainer"
const DEFAULT_TITLE_TEMPLATE = "%s"
const DEFAULT_DESCRIPTION ="The Largest, Most Trusted, Complete Training Marketplace Site in Indonesia which provides various training that can be tailored to your needs | Improve your skills with PasarTrainer now. | Pelatihan Terlengkap ✓ Profesional ✓ Terpercaya."
const SITE_NAME = "PasarTrainer"
const DEFAULT_OG_IMAGE = "https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1721139863/open-graph/page/home.jpg"
const TWITTER_HANDLE = "@pasartrainer"
const TWITTER_CARD_TYPE = "summary_large_image"
const FAVICON_LINK = "/favicon.ico"

export default {
  DEFAULT_TITLE,
  DEFAULT_TITLE_TEMPLATE,
  DEFAULT_DESCRIPTION,
  DEFAULT_CANONICAL,
  SITE_NAME,
  DEFAULT_OG_IMAGE,
  TWITTER_HANDLE,
  TWITTER_CARD_TYPE,
  FAVICON_LINK,
}
