export interface ContainerProps {
  children: React.ReactNode
  customClass?: string
}

function ContainerComponent({ children, customClass = "" }: ContainerProps) {
  if (customClass) {
    return (
      <div className={customClass}>
        <div className="mx-0 sm:container sm:mx-auto">{children}</div>
      </div>
    )
  }

  return <div className="mx-0 sm:container sm:mx-auto">{children}</div>
}

export default ContainerComponent
