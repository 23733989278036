import Header from "@components/Layouts/Header"
import Navigation from "@components/Layouts/Navigation"
import Link from "next/link"
import { useContext, useEffect, useState } from "react"
import Alert from "lib/components/Alert"
import Loading from "lib/components/Loading"
import Icon from "lib/components/Icons"
import { isDesktop } from "react-device-detect"
import router from "next/router"
import { LoadingContext } from "context/LoadingContext"
import { ErrorContext } from "context/ErrorContext"
import dynamic from "next/dynamic"
import clientData from "./client-navigation.json"
import BottomNavigation from "../BottomNavigation"
import DrawerDefault from "../DefaultLayout/DrawerDefault"

const Image = dynamic(() => import("lib/components/Image"), {
  ssr: false,
})

function AdminLayout({ children, handleLogout }) {
  const { mainError, mainErrorMessage } = useContext(ErrorContext)
  const { loading } = useContext(LoadingContext)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
    setIsMobile(!isDesktop)
  }, [])

  const checkisProgramDetail = () =>
    router.pathname !== "/[category]/inhouse/[program]" &&
    router.pathname !== "/[category]/[type]/[program]/[slug]"

  return (
    <div className="relative min-h-screen flex">
      <style>
        {`
          #qismo-widget {
            display: none;
          }
          #qiscus-app-button-close {
            display: none;
          }
        `}
      </style>
      <div className={`${openDrawer ? "w-22" : "w-96"} border-r hidden sm:block`}>
        <div className="h-32 sm:h-16 flex justify-center items-center relative">
          <div className="-ml-8 cursor-pointer mt-1">
            <Link href="/" legacyBehavior>
              <a className={`${openDrawer ? "hidden" : "block"}`}>
                <Image
                  src="/assets/images/logo-large-primary.png"
                  alt="Picture of the author"
                  width={148}
                  height={33}
                />
              </a>
            </Link>
          </div>
          <div className={`absolute ${openDrawer ? "right-8" : "right-4"} top-6`}>
            <Icon
              iconName="hamburger"
              styles="cursor-pointer"
              onClick={() => setOpenDrawer(!openDrawer)}
            />
          </div>
        </div>
        <Navigation dataNavigation={clientData} iconOnly={openDrawer} />
      </div>
      <div className="w-full">
        <Header theme="light" withoutLogo handleLogout={handleLogout} isAdmin />
        <div>
          <DrawerDefault handleLogout={handleLogout} />
        </div>
        <div className="min-h-screen pb-32 sm:pb-0">
          {mainError && (
            <Alert
              title="Oops!"
              subtitle={mainErrorMessage || "Something went wrong, please refresh this page."}
              color="danger"
              withoutClose
            />
          )}
          {loading && <Loading />}
          <div className={loading || mainError ? "hidden" : "h-inherit sm:pb-32"}>{children}</div>
        </div>
      </div>
      {isMobile && checkisProgramDetail() && <BottomNavigation />}
    </div>
  )
}

export default AdminLayout
