import Icon from "lib/components/Icons"

interface ButtonCloseQiscus {
  closeQiscusApp: () => void
}

const ButtonCloseQiscus = ({ closeQiscusApp }: ButtonCloseQiscus) => (
  <div
    id="qiscus-app-button-close"
    onClick={closeQiscusApp}
    tabIndex={0}
    role="button"
    className="h-4 w-4 bg-primary shadow text-white z-40 rounded-full fixed right-4 lg:right-10 lg:bottom-20 bottom-36 flex items-center justify-center">
    <Icon iconName="close" />
  </div>
)

export default ButtonCloseQiscus
