import fetcher from "lib/fetcher"
import { GetCategoriesQuery } from "@graphql/program/getCategories"
import Button from "lib/components/Button"
import Divider from "lib/components/Divider"
import Icon from "lib/components/Icons"
import LoadingComponent from "lib/components/Loading"
import Text from "lib/components/Text"
import { LanguageContext } from "context/LanguageContext"
import _ from "lodash"
import { useContext, useEffect, useState } from "react"
import useSWR from "swr"

type keySelectCategory = "category_name" | "category_name_indo"

interface Props {
  onSelectCategory?: (categorySlug: string) => void
}

function CardCategories({
  onSelectCategory,
}: Props) {
  const { useTranslation: t, language } = useContext(LanguageContext)
  const { data: dataCategory, isLoading } = useSWR<GetCategoriesQuery>("/api/categories", fetcher)

  const [categories, setCategories] = useState([])
  const [searchCategoryList, setSearchCategoryList] = useState([])
  const [keySelectorCategory, setKeySelectCategory] = useState<keySelectCategory>("category_name")
  const [searchValue, setSearchValue] = useState(null)

  useEffect(() => {
    if (dataCategory?.program_category.length > 0) {
      const tempCategory = []
      dataCategory?.program_category.filter(item => !item.parent_id).forEach((categoryData) => {
        if (categoryData.categories.length <= 0) {
          tempCategory.push({
            ...categoryData,
            categories: [categoryData],
          })
        } else {
          tempCategory.push(categoryData)
        }
      })
      setCategories(tempCategory)
      setSearchCategoryList(tempCategory)
    }

    return () => setCategories([])
  }, [dataCategory])

  useEffect(() => {
    setKeySelectCategory("category_name")
    if (language === "ID") {
      setKeySelectCategory("category_name_indo")
    }
  }, [t])

  const searchCategories = (e: { target: { value: string } }) => {
    if (e.target?.value.length > 0) {
      const { value } = e.target
      const search = new RegExp(value, "i") // prepare a regex object
      const resultSearchCategory = categories
        .filter(item => item.categories
          .some(category => {
            if (language === "ID") {
              const categoryName = category.category_name_indo || category.category_name
              return search.test(categoryName)
            } 

            return search.test(category.category_name)
          }),
        )
        .map(element => {
          const result = { ...element, categories: element.categories.filter(
            category => {
              if (language === "ID") {
                const categoryName = category.category_name_indo || category.category_name
                return search.test(categoryName)
              } 

              return search.test(category.category_name)
            },
          )}
        return result
      })
      setSearchCategoryList(resultSearchCategory)
    } else {
      setSearchCategoryList(categories)
    }
    setSearchValue(e.target.value)
  }

  if (isLoading) {
    return (
      <div className="top-8 left-0 justify-center z-40 flex h-full w-full overflow-y-auto lg:p-4 bg-white">
        <LoadingComponent size="small"/>
      </div>
    )
  }

  return (
    <section className="top-0 left-0 right-0 bottom-0 z-40 flex h-full w-full overflow-y-auto bg-opacity-80 lg:p-4">
      <div className="flex w-full flex-col justify-between rounded-md bg-white pt-8 lg:border lg:pt-0 lg:shadow-lg">
        {/* serach categories */}
        <div className="fixed sm:relative top-0 pt-3.5 sm:pt-7 px-4 sm:px-8 w-full bg-white z-10 shadow-sm pb-4">
          <h1 className="font-bold text-base leading-5 pl-8 sm:pl-0">{t("categories")}</h1>
          <div className="relative mt-2">
            <div className="lg:flex lg:w-full lg:space-x-10">
              <div className="mr-auto flex w-full items-center rounded-lg border border-gray-200 pl-5 ">
                <div className="mr-4 text-gray-600 hover:text-gray-900">
                  <Icon iconName="search" />
                </div>
                <input
                  className="w-full rounded-lg py-2.5 pl-1 text-sm font-normal text-gray-400"
                  type="text"
                  placeholder={t("type_to_search")}
                  onChange={(e) => searchCategories(e)}
                />
              </div>
            </div>
          </div>
        </div>
        {/* content */}
        <div className="flex h-full w-full flex-wrap overflow-hidden mb-8 sm:mb-0">
          <div className="h-full w-full overflow-scroll py-8 pt-24 mt-4 sm:mt-0 sm:px-8 sm:pb-8 sm:pt-0">
            {searchCategoryList.length > 0 &&
              searchCategoryList.map((category, index: React.Key | null | undefined) => (
                <div key={index}>
                  <div className="w-full flex flex-wrap overflow-hidden px-4 lg:px-0">
                    <div className="w-full pt-4" tabIndex={0} role="button" onClick={() => onSelectCategory?.(category.slug)}>
                      <h1 className="block w-full font-semibold text-base leading-5">
                        {category[keySelectorCategory] || category.category_name}
                      </h1>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4 w-full">
                      {category?.categories?.map((categoryChild, indexChild) => (
                          <div key={indexChild}>
                            <Button
                              color="white"
                              variant="text"
                              iconRight="arrow-right"
                              align="left"
                              justifyContent="between"
                              fullWidth
                              customClass="pt-4 pl-4"
                              onClick={() => onSelectCategory?.(categoryChild.slug)}
                              colorText="gray">
                              {categoryChild[keySelectorCategory] || categoryChild.category_name}
                            </Button>
                          </div>
                        ),
                      )}
                    </div>
                    <Divider customClass="w-full my-0 lg:hidden block border-gray-200 mt-4 border-t-0" />
                  </div>
                </div>
            ))}
            {searchCategoryList.length <= 0 &&
              <Text alignText="center" customClass="mt-8">{t("not_available")}</Text>
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default CardCategories
