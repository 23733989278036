import Text from "lib/components/Text"
import * as Sentry from "@sentry/react"
import Button from "lib/components/Button"
import { useRouter } from "next/router"

function FallbackComponent() {
  const router = useRouter()
  return (
    <div className="w-full h-full flex flex-col justify-center items-center fixed">
      <img src="/assets/images/register-business.png" alt=""/>
      <Text size="large">Oops something went wrong!</Text>
      <div className="h-10" />
      <Button
        variant="contained" 
        type="button" 
        color="primary"
        onClick={router.reload}
      >
        Refresh your page
      </Button>
    </div>
  )
}

const ErrorBoundary = ({children}: Sentry.ErrorBoundaryProps) => (
    <Sentry.ErrorBoundary 
      fallback={<FallbackComponent />}
    >
      {children}
    </Sentry.ErrorBoundary>
  )

export default ErrorBoundary