import { withIronSession } from "next-iron-session"

export default function withSession(handler: any) {
  return withIronSession(handler, {
    password: process.env.NEXT_PUBLIC_SECRET_COOKIE || "",
    cookieName: "pasartrainerClient",
    cookieOptions: {
      secure: process.env.NODE_ENV === "production",
    },
  })
}