const config = {
  NEXT_PUBLIC_URL: process.env.NEXT_PUBLIC_HASURA_URL,
  NEXT_PUBLIC_HASURA_URL: process.env.NEXT_PUBLIC_HASURA_URL,
  NEXT_PUBLIC_APP_CODE: process.env.NEXT_PUBLIC_APP_CODE,
  NEXT_PUBLIC_IMAGE_ASSET_URL: process.env.NEXT_PUBLIC_IMAGE_ASSET_URL,
  NEXT_PUBLIC_CLOUDINARY_NAME: process.env.NEXT_PUBLIC_CLOUDINARY_NAME,
  NEXT_PUBLIC_CLOUDINARY_PRESET: process.env.NEXT_PUBLIC_CLOUDINARY_PRESET,
  NEXT_PUBLIC_CLOUD_IMAGE_ID: process.env.NEXT_PUBLIC_CLOUD_IMAGE_ID,
  NEXT_PUBLIC_CLOUDINARY_FOLDER: process.env.NEXT_PUBLIC_CLOUDINARY_FOLDER,
  NEXT_PUBLIC_HUBSPOT_FORM: process.env.NEXT_PUBLIC_HUBSPOT_FORM,
  NEXT_PUBLIC_HUBSPOT_TRAINER_FORM: process.env.NEXT_PUBLIC_HUBSPOT_TRAINER_FORM,
  NEXT_PUBLIC_PORTAL_ID: process.env.NEXT_PUBLIC_PORTAL_ID,
  NEXT_PUBLIC_QISCUS_APPID: process.env.NEXT_PUBLIC_QISCUS_APPID,
  NEXT_PUBLIC_QISCUS_MULTICHAT_APPID: process.env.NEXT_PUBLIC_QISCUS_MULTICHAT_APPID,
  NEXT_PUBLIC_CLIENT_URL: process.env.NEXT_PUBLIC_CLIENT_URL,
  NEXT_PUBLIC_GOOGLE_ANALYTICS: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
  NEXT_PUBLIC_GHOST_HOST: process.env.NEXT_PUBLIC_GHOST_HOST,
  NEXT_PUBLIC_GHOST_KEY: process.env.NEXT_PUBLIC_GHOST_KEY,
  NEXT_PUBLIC_GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
}

type EnvKey = keyof typeof config

export const getEnv = (key: EnvKey) => {
  const isServer = typeof window === "undefined"
  if (!isServer) {
    return (window as any)?.SETTINGS?.[key]
  }
  return process.env?.[key]
}

export default config
