/* eslint-disable react/button-has-type */
import Icon from "lib/components/Icons"

export type roundedType = "small" | "medium" | "large" | "none" | "large-left" | "large-right"

export interface ButtonProps {
  variant: "contained" | "outlined" | "text"
  color:
    | "default"
    | "primary"
    | "secondary"
    | "inactive"
    | "danger"
    | "warning"
    | "success"
    | "white"
    | "muted"
    | "light-gray"
    | "dark"
    | "white-muted"
  colorText?: "gray" | "primary" | "muted" | "primary-light" | "light-gray"
  leadingText?: "normal" | "small" | "medium" | "large"
  align?: "center" | "left" | "right"
  type?: "submit" | "reset" | "button"
  size?: "small" | "medium" | "large"
  padding?: "small" | "medium" | "large" | "none" | "square"
  rounded?: roundedType
  loading?: boolean
  disabled?: boolean
  icon?: string
  iconRight?: string
  fullWidth?: boolean
  customClass?: string
  fontWeight?: "medium" | "normal" | "bold" | "semibold"
  letterSpacing?: "large" | "normal" | "small" | "medium"
  children?: React.ReactNode
  onClick?: () => void
  href?: string
  target?: "_blank" | "_self"
  justifyContent?: "center" | "between" | "even" | "start" | "end"
  buttonGap?: "small" | "medium" | "large" | "no-gap"
  iconType?: "stroke" | "fill"
  ariaLabel?: string
  widthIcon?: string | number
  heightIcon?: string | number
  iconColor?: string
}

function Button({
  children,
  variant,
  size,
  loading,
  color,
  padding,
  disabled,
  type = "button",
  icon = "",
  iconRight,
  onClick,
  fontWeight,
  rounded,
  fullWidth = false,
  customClass = "",
  letterSpacing,
  href,
  leadingText,
  colorText,
  align,
  target = "_self",
  justifyContent = "center",
  buttonGap = "medium",
  iconType = "fill",
  ariaLabel,
  widthIcon,
  heightIcon,
  iconColor,
}: ButtonProps) {
  let colorUi = "text-default"
  let backgroundColor = "bg-primary"
  const hoverUi = "hover:bg-text-default-800"
  let borderColor = "border-primary"
  const isFull = fullWidth ? "w-full" : ""
  let textSize = "text-sm"
  let paddingButton = "px-4 py-2"
  let spacingText = "tracking-wide"
  let textWeight = "font-medium"
  let roundedButton = "rounded"
  let leadingForText = ""
  let alignText = "justify-center"
  let justifyButton = "items-center justify-center"
  let buttonGapSize = "gap-4"

  switch (color) {
    case "primary":
      colorUi = "text-white"
      if (variant === "outlined" || variant === "text") {
        colorUi = "text-primary"
      }
      borderColor = "border-primary"
      break
    case "secondary":
      colorUi = "text-white"
      backgroundColor = "bg-secondary"
      if (variant === "outlined" || variant === "text") {
        colorUi = "text-secondary"
      }
      borderColor = "border-secondary"
      break
    case "inactive":
      colorUi = "text-white"
      backgroundColor = "bg-gray-600"
      if (variant === "outlined" || variant === "text") {
        colorUi = "text-gray-600"
      }
      borderColor = "border-gray-600"
      break
    case "danger":
      colorUi = "text-white"
      backgroundColor = "bg-red-600"
      if (variant === "outlined" || variant === "text") {
        colorUi = "text-red-600"
      }
      borderColor = "border-red-600"
      break
    case "warning":
      colorUi = "text-white"
      backgroundColor = "bg-yellow-600"
      if (variant === "outlined" || variant === "text") {
        colorUi = "text-yellow-600"
      }
      borderColor = "border-yellow-600"
      break
    case "success":
      colorUi = "text-white"
      backgroundColor = "bg-green-600"
      if (variant === "outlined" || variant === "text") {
        colorUi = "text-green-600"
      }
      borderColor = "border-green-600"
      break
    case "white":
      if (variant === "outlined" || variant === "text") {
        colorUi = "text-white"
        if (variant === "outlined") {
          borderColor = "border-white"
        }
      } else {
        colorUi = "text-black"
        borderColor = "border-black"
      }
      backgroundColor = "bg-white shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]"
      break
    case "muted":
      if (variant === "outlined") {
        colorUi = "text-gray-500"
        borderColor = "border-gray-400"
      }

      if (variant === "text") {
        colorUi = "text-gray-400"
      }

      if (variant === "contained") {
        colorUi = "text-gray-700"
        borderColor = "border-gray-200"
      }
      backgroundColor = "bg-gray-200 bg-opacity-75"
      break
      case "white-muted":
        if (variant === "outlined") {
          colorUi = "text-gray-500"
          borderColor = "border-gray-400"
        }
  
        if (variant === "text") {
          colorUi = "text-gray-400"
        }
  
        if (variant === "contained") {
          colorUi = "text-black"
          borderColor = "border border-gray-300"
        }
        backgroundColor = "bg-white"
        break
    case "light-gray":
      if (variant === "outlined") {
        colorUi = "text-gray-400"
        borderColor = "border-gray-300"
      }

      if (variant === "text") {
        colorUi = "text-gray-300"
      }

      if (variant === "contained") {
        colorUi = "text-gray-500"
        borderColor = "border-gray-300"
      }
      backgroundColor = "bg-gray-200 bg-opacity-75"
      break
    case "dark":
      if (variant === "outlined") {
        colorUi = "text-gray-900"
        borderColor = "border-gray-900"
      }

      if (variant === "text") {
        colorUi = "text-gray-900"
      }

      if (variant === "contained") {
        colorUi = "text-gray-500"
        borderColor = "border-gray-900"
      }
      backgroundColor = "bg-gray-200 bg-opacity-75"
      break
    default:
      break
  }
  switch (align) {
    case "center":
      alignText = "justify-center"
      break
    case "left":
      alignText = "justify-start"
      break
    case "right":
      alignText = "justify-end"
      break
    default:
      break
  }
  switch (colorText) {
    case "gray":
      colorUi = "text-gray-800"
      break
    case "light-gray":
      colorUi = "text-gray-600"
      break
    case "muted":
      colorUi = "text-gray-400"
      break
    case "primary":
      colorUi = "text-primary"
      break
    case "primary-light":
      colorUi = "text-blue-900"
      break
    default:
  }

  switch (leadingText) {
    case "small":
      leadingForText = "leading-3"
      break
    case "normal":
      leadingForText = "leading-4"
      break
    case "medium":
      leadingForText = "leading-5"
      break
    case "large":
      leadingForText = "leading-10"
      break
    default:
  }
  switch (variant) {
    case "contained":
      colorUi = `${colorUi} focus:outline-none ${backgroundColor} ${borderColor} ${
        disabled ? "opacity-50" : hoverUi
      }`
      break
    case "outlined":
      colorUi = `${colorUi} focus:outline-none border ${borderColor} ${
        disabled ? "opacity-50" : ""
      }`
      break
    case "text":
      colorUi = `${colorUi} focus:outline-none ${disabled ? "opacity-50" : ""}`
      break
    default:
      break
  }

  switch (size) {
    case "small":
      textSize = "text-xs"
      break

    case "large":
      textSize = "text-base"
      break
    default:
      break
  }

  switch (buttonGap) {
    case "small":
      buttonGapSize = "gap-2"
      break

    case "large":
      buttonGapSize = "gap-6"
      break
      
    case "no-gap":
      buttonGapSize = "gap-0"
      break
    default:
      break
  }

  switch (letterSpacing) {
    case "normal":
      spacingText = "tracking-normal"
      break
    case "small":
      spacingText = "tracking-small"
      break
    case "medium":
      spacingText = "tracking-medium"
      break
    default:
      break
  }

  switch (justifyContent) {
    case "between":
      justifyButton = "justify-between"
      break
    case "even":
      justifyButton = "justify-evenly"
      break
    case "start":
      justifyButton = "justify-start"
      break
    case "end":
      justifyButton = "justify-end"
      break
    default:
      break
  }

  if (variant === "text") paddingButton = "p-0"

  switch (padding) {
    case "large":
      paddingButton = "px-4 py-3"
      break
    case "small":
      paddingButton = "px-3 py-1.5"
      break
    case "none":
      paddingButton = "p-0"
      break
    case "square":
      paddingButton = "p-2"
      break
    default:
      break
  }

  switch (fontWeight) {
    case "bold":
      textWeight = "bold"
      break
    case "normal":
      textWeight = "font-normal"
      break
    case "semibold":
      textWeight = "font-semibold"
      break
    default:
      break
  }

  switch (rounded) {
    case "medium":
      roundedButton = "rounded-md"
      break
    case "large":
      roundedButton = "rounded-lg"
      break
    case "large-left":
      roundedButton = "rounded-l-lg"
      break
    case "large-right":
      roundedButton = "rounded-r-lg"
      break
    case "none":
      roundedButton = "rounded-none"
      break
    default:
      break
  }

  const checkLoading = () => {
    if (loading) {
      return (
        <div className="relative">
          <div className="w-full flex opacity-0 items-center gap-4">
            {icon && (
              <div>
                <Icon type={iconType} iconName={icon} width={widthIcon || "22px"} height={heightIcon || "22px"} color={iconColor || colorUi} />
              </div>
            )}
            <span className="flex">{children}</span>
            {iconRight && (
              <div>
                <Icon
                  type={iconType}
                  iconName={iconRight}
                  width="22px"
                  height="22px"
                  color={iconColor || colorUi}
                />
              </div>
            )}
          </div>
          <div className="absolute inset-0 flex justify-center z-10">
            <svg
              className={`animate-spin h-5 w-5 ${iconColor || colorUi}`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24">
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          </div>
        </div>
      )
    }

    return (
      <>
        {href ? (
          <a href={href} target={target} className=" w-full h-full flex justify-center">
            <div className={`flex ${justifyButton} ${isFull} ${buttonGapSize}`}>
              {icon && (
                <div>
                  <Icon
                    type={iconType}
                    iconName={icon}
                    width={widthIcon || "22px"} 
                    height={heightIcon || "22px"}
                    color={iconColor || colorUi}
                  />
                </div>
              )}
              {children}
              {iconRight && (
                <div>
                  <Icon
                    type={iconType}
                    iconName={iconRight}
                    width="22px"
                    height="22px"
                    color={iconColor || colorUi}
                  />
                </div>
              )}
            </div>
          </a>
        ) : (
          <div className={`flex ${justifyButton} ${isFull} ${buttonGapSize}`}>
            {icon && (
              <div>
                <Icon type={iconType} iconName={icon} width={widthIcon || "22px"} height={heightIcon || "22px"} color={iconColor || colorUi} />
              </div>
            )}
            <div className="contents">{children}</div>
            {iconRight && (
              <div>
                <Icon
                  type={iconType}
                  iconName={iconRight}
                  width="22px"
                  height="22px"
                  color={iconColor || colorUi}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
  }
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${customClass} ${textWeight} ${paddingButton} ${roundedButton} ${leadingForText} ${spacingText} flex items-center ${alignText} ${colorUi} ${isFull} ${textSize}`}
      disabled={disabled}
      aria-label={ariaLabel}>
      {checkLoading()}
    </button>
  )
}

export default Button
