import ButtonCloseQiscus from "@components/shared-components/ButtonCloseQiscus"
import useButtonCloseQiscus from "lib/hooks/useButtonCloseQiscus"
import ImageComponent from "lib/components/Image"
import { useRef } from "react"
import { useReactToPrint } from "react-to-print"
import Link from "next/link"
import Text from "lib/components/Text"

const Heading = ({ onPrint }) => (
  <div className="py-4 fixed w-full top-0 shadow-lg z-20 bg-white">
    <div className="flex justify-between items-center px-4 lg:px-0 lg:w-[760px] mx-auto">
      <Link href="/orders/" legacyBehavior>
        <ImageComponent
          src="https://res.cloudinary.com/djnkdrfdz/image/upload/v1681699931/Logo_PasarTrainer_d9vta4.webp"
          objectFit="cover"
          alt="logo"
          width={200}
          height={43}
        />
      </Link>

      <div
        className="flex justify-end items-center gap-2 self-stretch "
        onClick={onPrint}
        role="button"
        tabIndex={0}>
        <div className="flex items-center">
          <Text
            customClass="tracking-[0.35px] leading-5"
            size="medium"
            color="primary"
            weight="semibold">
            Print
          </Text>
        </div>
        <div className="w-6 h-6 relative">
          <ImageComponent
            objectFit="cover"
            alt="logo"
            src="https://res.cloudinary.com/djnkdrfdz/image/upload/v1693438806/heroiconsPrinter_20Solid_odad4o.svg"
          />
        </div>
      </div>
    </div>
    <div className="w-full h-0" />
  </div>
)

function PrintLayout({ children }) {
  const { closeQiscusApp, isQiscusHidden } = useButtonCloseQiscus()
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  return (
    <div className="relative min-h-screen ">
      <style>
        {`
          ${
            isQiscusHidden
              ? "#qismo-widget {display:none} #qiscus-app-button-close { display:none }"
              : ""
          }
          .svelte-1xkyjie {
            display: none;
          }
        `}
      </style>
      <Heading onPrint={handlePrint} />
      <div ref={componentRef} className="lg:w-[760px] mx-auto">
        <div className="z-0 mt-20">{children}</div>
      </div>
      <ButtonCloseQiscus closeQiscusApp={closeQiscusApp} />
    </div>
  )
}

export default PrintLayout
