import { useEffect } from "react"
import { isMobile } from "react-device-detect"

import { useAuth } from "lib/auth"
import { useRouter } from "next/router"

import { CategoryProvider } from "context/CategoryContext"
import Icon from "lib/components/Icons"

import BottomNavigation from "../BottomNavigation"
import Footer from "../Footer"
import DrawerDefault from "../DefaultLayout/DrawerDefault"
import Header from "../Header"

export type QiscusAppStorage = "hidden" | "visible" | null

function TransparentLayouts({ children, handleLogout }) {
  const router = useRouter()
  const { userProfile } = useAuth()

  const closeQiscusApp = () => {
    const qiscusApp = document.getElementById("qismo-widget")
    const qiscusAppButtonClose = document.getElementById("qiscus-app-button-close")
    qiscusApp.classList.add("hidden")
    qiscusAppButtonClose.classList.add("hidden")
    localStorage.setItem("qiscusApp", "hidden")
  }

  useEffect(() => {
    if (localStorage) {
      const localStorageQiscusApp = localStorage.getItem("qiscusApp") as QiscusAppStorage
      const qiscusApp = document.getElementById("qismo-widget")
      const qiscusAppButtonClose = document.getElementById("qiscus-app-button-close")

      if (localStorageQiscusApp === "hidden") {
        qiscusApp?.classList.add("hidden")
        qiscusAppButtonClose.classList.add("hidden")
      } else {
        qiscusApp?.classList.remove("hidden")
        qiscusAppButtonClose.classList.remove("hidden")
      }
    }
  }, [])

  const checkisProgramDetail = () =>
    router.pathname !== "/register" &&
    router.pathname !== "/[category]/inhouse/[program]" &&
    router.pathname !== "/[category]/[type]/[program]/[slug]" &&
    !router.pathname.includes("checkout")

  const dataNavigationLoggedIn = [
    {
      url: "/overview",
      name: "Dashboard",
    },
  ]

  if (userProfile?.user_role === "ptc-user") {
    dataNavigationLoggedIn.push({
      url: "/my-classes",
      name: "My Classes",
    })
  }

  return (
    <div className="relative min-h-screen">
      <style>
        {`
          .svelte-1xkyjie {
            display: none;
          }
        `}
      </style>
      <div>
        <CategoryProvider>
          <Header withoutBorded isAbsolute handleLogout={handleLogout} />
          <div>
            <DrawerDefault handleLogout={handleLogout} />
          </div>
          <div
            id="qiscus-app-button-close"
            onClick={closeQiscusApp}
            tabIndex={0}
            role="button"
            className="h-4 w-4 bg-primary shadow text-white z-20 rounded-full fixed right-4 lg:right-10 lg:bottom-20 bottom-36 flex items-center justify-center">
            <Icon iconName="close" />
          </div>
          <div className="min-h-screen">{children}</div>
          <Footer />
        </CategoryProvider>
      </div>

      {isMobile && checkisProgramDetail() && <BottomNavigation />}
    </div>
  )
}

export default TransparentLayouts
