export const LIMIT_PER_PAGE = 20

export const ORDER_PROGRESS = [
  {
    label: "Checkout",
  },
  {
    label: "Payment",
  },
  {
    label: "Complete",
  },
]