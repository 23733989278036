type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer

export const gtmEvent = (eventName, rest) => {
  window.dataLayer?.push({
    event: eventName,
    ...rest,
  })
}

export const gtmEcommerce = (value) => {
  window.dataLayer?.push({
    ...value,
  })
}