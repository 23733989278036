import { createContext, useState } from "react"

export interface DrawerContextProps {
  toggleDrawer?(): void
  drawer?: boolean
}

export const DrawerContext = createContext<DrawerContextProps>({})

export function DrawerProvider({ children }) {

  const [drawer, setDrawer] = useState(false)

   const toggleDrawer = () => {
     setDrawer((prevState) => !prevState)
   }

  return (
    <DrawerContext.Provider
      value={{
        drawer,
        toggleDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}
