import dynamic from "next/dynamic"
import ModalFilterCategories from "@components/shared-components/Modal/ModalFilterCategories"
import { DrawerContext } from "context/DrawerContext"
import { gtmEvent } from "lib/gtm"
import { checkStringContain, handleChar } from "lib/helpers"
import Icon from "lib/components/Icons"
import Text from "lib/components/Text"
import { LanguageContext } from "context/LanguageContext"
import { useAuth } from "lib/auth"
import Link from "next/link"
import { useContext, useEffect, useState } from "react"
import { useRouter } from "next/router"
import Image from "next/legacy/image"
import JoinUsModal from "../Header/joinModal"
import NavigationItemSidebar from "../Navigation/sidebar"

const Drawer = dynamic(
  () => import("react-modern-drawer"),
  { ssr: false },
)

const dataNavigation = []

function DrawerDefault({ handleLogout }) {
  const router = useRouter()
  const { useTranslation: t } = useContext(LanguageContext)
  const { toggleDrawer, drawer } = useContext(DrawerContext)

  const [showModalJoin, setShowModalJoin] = useState<boolean>(false)
  const [showModalCategory, setShowModalCategory] = useState<boolean>(false)

  const { userProfile, isLoggedIn } = useAuth()
  const homePath = isLoggedIn ? "/homepage" : "/"

  useEffect(() => {
    if (drawer) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "scroll"
    }
  }, [drawer])

  const handleToggleModalJoin = () => {
    setShowModalJoin(!showModalJoin)
    if (!showModalJoin) {
      toggleDrawer()
    }
  }

  const dataNavigationLoggedIn = [
    {
      url: "/overview",
      name: "Dashboard",
    },
    {
      url: "/certifications",
      name: "Certificates",
    },
  ]

  const childrenPrograms = [
    {
      name: t("programs_inhouse"),
      url: "/inhouse",
    },
    {
      name: t("programs_public"),
      url: "/programs/all?type=public",
    },
  ]

  const childrenServices = [
    {
      name: t("corporate_solution"),
      url: "/business",
    },
    {
      name: t("training_organizer_services"),
      url: "https://wa.me/6281312000375",
    },
    {
      name: t("training_management_services"),
      url: "https://wa.me/6281312000375",
    },
  ]

  return (
    <Drawer
      open={drawer}
      onClose={toggleDrawer}
      direction="left"
      style={{
        overflowY: "auto",
        width: "100%",
        height: "100%",
      }}
      className="h-full"
      >
      {!isLoggedIn && (
      <div className="w-full bg-white h-full flex flex-col">
        <div className="flex flex-row justify-between items-center p-4">
          <div className="block sm:hidden" />
          <Image
            src="/assets/images/logo-large-primary.png"
            alt="Logo"
            width={148}
            height={33}
          />
          <Icon
            iconName="close"
            styles="cursor-pointer"
            color="#286DCC"
            onClick={toggleDrawer}
          />
        </div>

        <div className="flex flex-col justify-between px-4 pt-3 h-full">
          <div className="flex flex-col">
            <div
              role="button"
              tabIndex={0}
              className="text-left flex justify-between py-3"
              onClick={toggleDrawer}
            >
              <Link href="/" legacyBehavior>
                <a>{t("home")}</a>
              </Link>
            </div>
            <div
              role="button"
              tabIndex={0}
              className="text-left flex justify-between py-3"
              onClick={toggleDrawer}
            >
              <Link href="/my-classes" legacyBehavior>
                <a>{t("my_classes")}</a>
              </Link>
            </div>
            <div
              role="button"
              tabIndex={0}
              className="text-left flex justify-between py-3"
              onClick={() => {
                toggleDrawer()
                document.body.style.overflow = "hidden"
                setShowModalCategory(!showModalCategory)
              }}
            >
              <Text>{t("categories")}</Text>
              <Icon iconName="arrow-right" styles="ml-2" />
            </div>
            {dataNavigation.map((item, index) => (
              <a
                key={index}
                className="py-3"
                target="_blank"
                href={item.url}
                rel="noreferrer"
              >
                {t(item.name.toLowerCase())}
              </a>
            ))}
            <NavigationItemSidebar
              name="Program"
              withIcon={false}
              childItem={childrenPrograms}
              toggleDrawer={toggleDrawer}
            />
            <NavigationItemSidebar 
              name="Services" 
              withIcon={false} 
              childItem={childrenServices}
              toggleDrawer={() => toggleDrawer()}
              defaultShow
            />
          </div>

          <div className="flex flex-col space-y-3 my-4">
            <hr className="my-4 bg-[#D1D5DB]" />
            <div
              role="button"
              tabIndex={0}
              className="bg-primary text-white rounded text-center py-2"
              onClick={() => {
                toggleDrawer()
              }}>
              <Link href={checkStringContain(router.asPath, ["/blog", "/inhouse"]) ? `/login?r=${encodeURIComponent(router.asPath)}` : "/login"} legacyBehavior>
                <a className="py-2">{t("login")}</a>
              </Link>
            </div>
            <button type="button" className="py-2 rounded border border-primary text-primary" onClick={handleToggleModalJoin}>
              {t("sign_up")}
            </button>
          </div>
        </div>
      </div>
      )}
      {isLoggedIn && (
        <div className="w-full bg-white h-full flex flex-col">
          <div className="flex flex-row justify-between items-center p-4">
            <div className="block sm:hidden" />
            <Image
              src="/assets/images/logo-large-primary.png"
              alt="Logo"
              width={148}
              height={33}
            />
            <Icon
              iconName="close"
              styles="cursor-pointer"
              color="#286DCC"
              onClick={toggleDrawer}
            />
          </div>
          <div className="flex flex-col justify-between px-4 pt-3 h-full">
            <div className="flex flex-col">
              <div
                role="button"
                tabIndex={0}
                className="text-left flex justify-between py-3"
                onClick={toggleDrawer}
              >
                <Link href="/" legacyBehavior>
                  <a>{t("home")}</a>
                </Link>
              </div>
              {dataNavigationLoggedIn.map((itemChildren, indexItem) => (
                <Link key={indexItem} href={itemChildren.url} legacyBehavior>
                  <a className="py-3" target="_blank">
                    {t(handleChar({ value: itemChildren.name, replacement: "_" }).toLowerCase())}
                  </a>
                </Link>
              ))}
              <div
                role="button"
                tabIndex={0}
                className="text-left flex justify-between py-3"
                onClick={toggleDrawer}
              >
                <Link href="/my-classes" legacyBehavior>
                  <a>{t("my_classes")}</a>
                </Link>
              </div>
              <div
                role="button"
                tabIndex={0}
                className="text-left flex justify-between py-3"
                onClick={() => {
                  toggleDrawer()
                  document.body.style.overflow = "hidden"
                  setShowModalCategory(!showModalCategory)
                }}
              >
                <Text>{t("categories")}</Text>
                <Icon iconName="arrow-right" styles="ml-2" />
              </div>
              <NavigationItemSidebar
                name="Program"
                withIcon={false}
                childItem={childrenPrograms}
                toggleDrawer={toggleDrawer}
              />
              <NavigationItemSidebar 
                name="Services" 
                withIcon={false} 
                childItem={childrenServices}
                toggleDrawer={() => toggleDrawer()}
                defaultShow
              />
            </div>

            <div className="flex flex-col space-y-2 my-4">
              <hr className="my-4 bg-[#D1D5DB]" />
              <button
              type="button"
              className="py-2 rounded bg-primary text-white"
              onClick={() => {
                gtmEvent("ce_logout", {
                  user_id: userProfile.user_id,
                  custom_user_id: userProfile.user_id,
                  account_type: userProfile.user_role !== "ptc-user" ? "Company" : "Individual",
                  job_title: userProfile.job_title,
                })
                handleLogout()
              }}>
              {t("logout")}
            </button>
            </div>
          </div>
        </div>
      )}
      <ModalFilterCategories
        onClose={() => {
          setShowModalCategory(false)
          toggleDrawer()
          document.body.style.overflow = "scroll"
        }}
        isOpen={showModalCategory}
        onSelectCategory={(categorySlug: string) => {
          document.body.style.overflow = "hidden"
          setShowModalCategory(false)
          window.location.href = `/programs/${categorySlug}`
        }}
      />

      {showModalJoin && <JoinUsModal onClose={handleToggleModalJoin} />}
    </Drawer>
  )
}

export default DrawerDefault
