import HeaderFeedback from "../Header/feedback"

function FeedbackLayout({ children }) {
  return (
    <div className="relative min-h-screen">
      <div>
        <HeaderFeedback theme="light"/>
        <div className="pt-16">{children}</div>
      </div>
    </div>
  )
}

export default FeedbackLayout
