import IconsPath from "lib/JSON/icons_path.json"
import IconsURL from "lib/JSON/icons_url.json"

export interface IconProps {
  width?: string | number
  height?: string | number
  widthBox?: string | number
  heightBox?: string | number
  path?: string
  pathName?: string
  color?: string
  onClick?(): void
  styles?: string
  iconName?: string
  type?: "stroke" | "fill"
  strokeWidth?: string | number
}

function Icon({
  width,
  height,
  widthBox,
  heightBox,
  path,
  color,
  onClick,
  styles,
  iconName,
  pathName,
  type = "fill",
  strokeWidth = "1.5",
}: IconProps) {
  if (pathName) {
    const IconSVG = IconsURL[pathName] || IconsURL.default

    return <img src={IconSVG} alt={pathName} width={width || 30} height={height || 30} />
  }

  if (type === "stroke") {
    return (
      <svg
        className={styles}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke={color || "currentColor"}
        strokeWidth={strokeWidth} 
        color={color || "inherit"}
        width={width || 24}
        height={height || 24}
        viewBox={`0 0 ${widthBox || 24} ${heightBox || 24}`}>
        <path strokeLinecap="round" strokeLinejoin="round" d={IconsPath[iconName] || path} />
      </svg>
    )
  }

  return (
    <svg
      className={styles}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      color={color || "inherit"}
      width={width || 24}
      height={height || 24}
      viewBox={`0 0 ${widthBox || 24} ${heightBox || 24}`}>
      <path fillRule="evenodd" clipRule="evenodd" d={IconsPath[iconName] || path} />
    </svg>
  )
}

export default Icon
