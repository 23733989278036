import Icon from "lib/components/Icons"
import Text from "lib/components/Text"
import { ORDER_PROGRESS } from "constants/common"
import { LanguageContext } from "context/LanguageContext"
import Image from "next/legacy/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext } from "react"
import { isMobile } from "react-device-detect"

interface HeaderCheckoutProps {
  theme?: "default" | "light"
  withoutBorded?: boolean
  withoutLogo?: boolean
}

function HeaderCheckout({ theme, withoutBorded, withoutLogo }: HeaderCheckoutProps) {
  const { useTranslation: t } = useContext(LanguageContext)
  const router = useRouter()
  let activeIndex = 0
  let titleBar = "checkout"

  if (router.pathname === "/checkout/[slug]/payment") {
    activeIndex = 1
    titleBar = "edit_participants"
  }

  if (router.pathname === "/checkout/finish") {
    activeIndex = 2
    titleBar = "complete"
  }

  let backgroundColor = "bg-primary"
  let assetLogo = "/assets/images/logo-large-secondary.png"
  const marginXHeader = "px-4 sm:px-0 sm:mx-auto"
  if (theme === "light") {
    backgroundColor = `bg-white ${withoutBorded ? "" : "shadow-bottom"}`
    assetLogo = "/assets/images/logo-large-primary.png"
  }

  return (
    <div className={`w-full z-10 ${backgroundColor} h-16 sm:h-32 sm:px-16 xl:h-20 fixed`}>
      {isMobile ? (
        <div className="flex items-center h-inherit px-2">
          <Icon iconName="arrow-left" width={32} height={32} onClick={() => router.back()} />
          <Text customClass="ml-2" weight="medium" size="large">
            {t(titleBar)}
          </Text>
        </div>
      ) : (
        <div className={`sm:container relative ${marginXHeader} flex h-full items-center`}>
          <div className="relative z-10 cursor-pointer h-full">
            <div className={`${withoutLogo ? "block sm:hidden h-inherit" : "block h-inherit"}`}>
              <Link href="/" legacyBehavior>
                <a className="flex items-center h-inherit">
                  <Image src={assetLogo} alt="Picture of the author" width={148} height={33} />
                </a>
              </Link>
            </div>
          </div>
          <div className="hidden sm:flex gap-x-8 absolute mx-auto w-full justify-center z-0">
            {ORDER_PROGRESS.map((item, index) => (
              <div className="mb-4 sm:mb-0" key={index}>
                <div className="relative flex items-center flex-row gap-x-8">
                  <div className="text-xs md:text-base">
                    {index === 0 ? (
                      <a href={activeIndex !== 1 ? "/" : `/checkout/${router.query.slug}`}>
                        <Text
                          size="medium"
                          weight={activeIndex === index ? "bold" : "medium"}
                          color={activeIndex === index ? "dark" : "muted"}>
                          {`${index + 1}. ${t(`${item.label.toLowerCase()}`)}`}
                        </Text>
                      </a>
                    ) : (
                      <Text
                        size="medium"
                        weight={activeIndex === index ? "bold" : "medium"}
                        color={activeIndex === index ? "dark" : "muted"}>
                        {`${index + 1}. ${t(`${item.label.toLowerCase()}`)}`}
                      </Text>
                    )}
                  </div>
                  {index !== ORDER_PROGRESS.length - 1 && (
                    <div className="text-xs md:text-base">
                      <Text size="medium" weight="medium" color="muted">
                        {">"}
                      </Text>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

HeaderCheckout.defaultProps = {
  theme: "default",
  withoutBorded: false,
  withoutLogo: false,
}

export default HeaderCheckout
