import { colorType } from "lib/interfaces"
import Icons from "lib/components/Icons"
import Text, { weightProps } from "lib/components/Text"
import { LanguageContext } from "context/LanguageContext"
import Link from "next/link"
import { useContext, useRef, useState } from "react"

export interface ProgramHeaderProps {
  color?: colorType
  weight?: weightProps
  isHeader?: boolean
  title?: string
  setActiveDropdown,
  activeDropdown,
  showMarquee,
}

function ProgramHeader({
  isHeader = false,
  color = "light",
  weight = "normal",
  title = "Training",
  setActiveDropdown,
  activeDropdown,
  showMarquee,
}: ProgramHeaderProps) {
  const { useTranslation: t } = useContext(LanguageContext)

  const refProgram: any = useRef()
  let animationType = "opacity-0"
  let animationIcon = "transform rotate-0 duration-200"
  let hexColor = isHeader ? "#FFFFFF" : "#000"

  if (activeDropdown === "program") {
    animationType = "opacity-100"
    animationIcon = "transform rotate-180 duration-200"
  }

  if (activeDropdown !== "program") {
    animationType = "hidden"
  }

  switch (color) {
    case "primary":
      hexColor = "#286DCC"
      break
    case "secondary":
      hexColor = "#00B49B"
      break
    default:
      break
  }

  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        onFocus={() => setActiveDropdown("program")}
        className="flex flex-row gap-2 items-center"
        onMouseOver={() => setActiveDropdown("program")}>
        <Text weight={weight} color={isHeader ? color : "dark"}>
          {title}
        </Text>
        <Icons iconName="expand-more" styles={animationIcon} color={hexColor} />
      </div>
      <div
        ref={refProgram}
        className={`transform ease-in-out duration-500 ${animationType} absolute mx-auto inset-0 ${showMarquee ? "top-24" : "top-16"} z-20 ${
          activeDropdown === "program" ? "hover:block" : "hidden"
        }`}
        onMouseLeave={() => setActiveDropdown(null)}
        >
        <div className="bg-white rounded-b-lg shadow-search flex flex-col  w-percent-80 mx-auto">
          <div className="flex w-full p-4 divide-x">
            <Link href="/inhouse" className="w-1/2 hover:underline hover:text-primary cursor-pointer">
              <Text weight="semibold">
                {t("programs_inhouse")}
              </Text>
              <Text customClass="text-sm !text-black text-decoration-inherit">
                {t("programs_inhouse_desc")}
              </Text>
            </Link>
            <a href="/programs/all?type=public" className="w-1/2 pl-6 hover:underline hover:text-primary cursor-pointer">
              <Text weight="semibold">
                {t("programs_public")}
              </Text>
              <Text customClass="text-sm !text-black text-decoration-inherit">
                {t("programs_public_desc")}
              </Text>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProgramHeader
