import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { CategoryProvider } from "context/CategoryContext"
import Footer from "../Footer"
import HeaderBlog from "../Header/blog"
import DrawerDefault from "../DefaultLayout/DrawerDefault"

const tabData = [
  {
    value: "latest",
    label: "Latest",
  },
  {
    value: "insight",
    label: "Insight",
  },
  {
    value: "trainer",
    label: "Trainer",
  },
  {
    value: "activity",
    label: "Activity",
  },
]

function BlogLayout({ children, handleLogout }) {
  const router = useRouter()
  // const [selectedTab, setSelectedTab] = useState<string>("latest")

  // useEffect(() => {
  //   if (router.pathname !== "/blog/[slug]") {
  //     router.push("/blog?tab=latest")
  //   }
  // }, [router.pathname])

  // const onClickTab = (value: string) => {
  //   if (value) {
  //     return router.push(`/blog?tab=${value}`)
  //   }
  //   return router.push("/blog")
  // }

  return (
    <>
      <CategoryProvider>
        <HeaderBlog theme="light" isFixed handleLogout={handleLogout} />
        <div>
          <DrawerDefault handleLogout={handleLogout} />
        </div>
        <div className="h-16 sm:h-32 xl:h-20 2xl:h-16" />
        {/* <div className="border-y">
          <div className="p-4 lg:px-0 lg:py-4 container mx-auto flex flex-row gap-x-8">
            {tabData.map((it, index) => (
              <div
                role="button"
                tabIndex={0}
                key={index}
                onClick={() => {
                  setSelectedTab(it.value)
                  onClickTab(it.value)
                }}
                className={`${selectedTab === it.value ? "border-b-2 border-primary" : ""}`}>
                <Text
                  alignText="center"
                  size="small"
                  color={selectedTab === it.value ? "primary" : "gray"}
                  customClass="mt-2">
                  {it.label}
                </Text>
              </div>
            ))}
          </div>
        </div> */}
        <div className="">{children}</div>
        <Footer />
      </CategoryProvider>
    </>
  )
}

export default BlogLayout
