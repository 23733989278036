import { GoogleOAuthProvider } from "@react-oauth/google"
import { SWRConfig } from "swr"
import SEO from "lib/SEO"
import { DefaultSeo } from "next-seo"
import fetcher from "lib/fetcher"
import Error from "next/error"
import Router from "next/router"
import { QiscusProvider } from "context/QiscusContext"
import { CategoryProvider } from "context/CategoryContext"
import { DrawerProvider } from "context/DrawerContext"
import { ErrorProvider } from "context/ErrorContext"
import LayoutWrapper from "@components/Layouts/LayoutWrapper"
import NProgress from "nprogress"
import "../styles/globals.css"
import "nprogress/nprogress.css"
import "react-modern-drawer/dist/index.css"
import "@fullcalendar/common/main.css"
import "react-toastify/dist/ReactToastify.css"
import "react-tooltip/dist/react-tooltip.css"
import "react-markdown-editor-lite/lib/index.css"
import "react-datepicker/dist/react-datepicker.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-phone-input-2/lib/style.css"

import AuthProvider from "lib/auth"
import { ApolloProvider } from "@apollo/client"
import { useApollo } from "lib/apollo"
import { ToastContainer, Slide, toast } from "react-toastify"
import { CheckoutProvider } from "lib/checkout"
import ErrorBoundary from "@components/ErrorBoundary"
import { UploadFileProvider } from "context/UploadContext"
import * as Sentry from "@sentry/nextjs"
import { LanguageProvider } from "context/LanguageContext"

NProgress.configure({ showSpinner: false })

Router.events.on("routeChangeStart", () => NProgress.start())
Router.events.on("routeChangeComplete", () => {
  NProgress.done()
})
Router.events.on("routeChangeError", () => NProgress.done())

const {
  DEFAULT_TITLE_TEMPLATE,
  DEFAULT_DESCRIPTION,
  DEFAULT_CANONICAL,
  SITE_NAME,
  DEFAULT_TITLE,
  DEFAULT_OG_IMAGE,
  TWITTER_HANDLE,
  FAVICON_LINK,
} = SEO
function App({ Component, pageProps }) {
  /**
   * Initialize apollo client provider config
   */

  const client = useApollo({ ...pageProps })
  if (pageProps.error) {
    return <Error statusCode={pageProps.error.statusCode || 500} />
  }

  return (
    <div>
      <ErrorBoundary>
        <DefaultSeo
          title={DEFAULT_TITLE}
          titleTemplate={DEFAULT_TITLE_TEMPLATE}
          description={DEFAULT_DESCRIPTION}
          canonical={DEFAULT_CANONICAL}
          openGraph={{
            type: "website",
            locale: "en_ID",
            url: DEFAULT_CANONICAL,
            site_name: SITE_NAME,
            title: SITE_NAME,
            description: DEFAULT_DESCRIPTION,
            images: [
              {
                url: DEFAULT_OG_IMAGE,
                alt: SITE_NAME,
                width: 1200,
                height: 630,
              },
            ],
          }}
          twitter={{
            handle: TWITTER_HANDLE,
            site: TWITTER_HANDLE,
            cardType: "summary_large_image",
          }}
          additionalLinkTags={[
            {
              rel: "shortcut icon",
              href: FAVICON_LINK,
            },
          ]}
        />
        <SWRConfig
          value={{
            fetcher,
            onError: (err) => {
              if (err.status !== 403 && err.status !== 404) {
                toast.error(err.message)
              }
            },
            revalidateOnFocus: false,
          }}>
          <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
            <LanguageProvider>
              <AuthProvider pageProps={pageProps}>
                <ApolloProvider client={client}>
                  <QiscusProvider>
                    <DrawerProvider>
                      <UploadFileProvider>
                        <ErrorProvider>
                          <CheckoutProvider>
                            <CategoryProvider>
                              <LayoutWrapper>
                                <Component {...pageProps} />
                              </LayoutWrapper>
                            </CategoryProvider>
                          </CheckoutProvider>
                        </ErrorProvider>
                      </UploadFileProvider>
                    </DrawerProvider>
                  </QiscusProvider>
                </ApolloProvider>
              </AuthProvider>
            </LanguageProvider>
          </GoogleOAuthProvider>
        </SWRConfig>
        <ToastContainer
          position="top-right"
          transition={Slide}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          limit={3}
          draggable
          pauseOnHover
        />
      </ErrorBoundary>
    </div>
  )
}

export default App
