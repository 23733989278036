import { createContext, useState } from "react"

export interface LoadingContextProps {
  setLoading?(): void
  unsetLoading?(): void
  loading?: boolean
}

export const LoadingContext = createContext<LoadingContextProps>({})

export function LoadingProvider({ children }) {

  const [loading, setLoadingStatus] = useState(false)

  const setLoading = () => {
    setLoadingStatus(true)
  }

  const unsetLoading = () => {
    setLoadingStatus(false)
  }

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
        unsetLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  )
}
