import axios from "axios"
import { createContext, useRef, useState } from "react"
import { toast } from "react-toastify"

export interface UploadContextProps {
  uploadFile?(filePath: Blob | string, callback: (any) => void, type?: string, showToast?: boolean): void,
  progressNumber?: number
}

export const UploadContext = createContext<UploadContextProps>({})

export function UploadFileProvider({ children }) {
  const toastId = useRef(null)
  const [progressValue, setProgressValue] = useState(0)

  const uploadFile = async (filePath: Blob, callback: (any) => void, type = "", showToast = true) => {
    toastId.current = null
    const formData = new FormData()
    let progress = 0

    const url = `https://api.cloudinary.com/v1_1/${process.env.NEXT_PUBLIC_CLOUDINARY_NAME}/image/upload`

    formData.append("file", filePath)
    formData.append("upload_preset", process.env.NEXT_PUBLIC_CLOUDINARY_PRESET)
    formData.append("folder", `${process.env.NEXT_PUBLIC_CLOUDINARY_FOLDER}/${type}`)

    if (showToast) {
      toastId.current = toast("Upload in Progress", {
        progress: 1,
      })
    }

    return axios
      .request({
        method: "post",
        url,
        data: formData,
        onUploadProgress: (p) => {
          progress = p.loaded / p.total
          setProgressValue(progress * 100)
          if (showToast) {
            if (toastId.current !== null) {
              toast.update(toastId.current, {
                progress,
              })
            }
          }
        },
      })
      .then((response) => {
        if (response && response.data && response.data.secure_url) {
          if (showToast) {
            toast.update(toastId.current, {
              render: "File has been upload",
              progress: 100,
              autoClose: 4000,
            })
          }
          if (callback) {
            callback(response.data)
            setProgressValue(0)
          }
        } else {
          throw new Error("Failed to upload")
        }
      })
      .catch(() => {
        toast.update(toastId.current, {
          render: "Failed to upload",
          type: "error",
          autoClose: 5000,
        })
      })
  }

  return (
    <UploadContext.Provider
      value={{
        uploadFile,
        progressNumber: progressValue,
      }}
    >
      {children}
    </UploadContext.Provider>
  )
}
