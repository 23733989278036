/* eslint-disable no-underscore-dangle */
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  DefaultOptions,
} from "@apollo/client"
import { onError } from "@apollo/link-error"
import { createUploadLink } from "apollo-upload-client"
import merge from "deepmerge"
import { IncomingHttpHeaders } from "http"
import fetch from "isomorphic-unfetch"
import isEqual from "lodash/isEqual"
import type { AppProps } from "next/app"
import { useMemo } from "react"
import consola from "consola"
import { getMainDefinition } from "@apollo/client/utilities"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"

const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__"

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

const createApolloClient = (headers: IncomingHttpHeaders | null = null) => {
  const enhancedFetch = (url: RequestInfo, init: RequestInit) =>
    fetch(url, {
      ...init,
      headers: {
        ...init.headers,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => response)

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  }

  // Create WebSocket link
  const wsLink = typeof window !== "undefined" ? new GraphQLWsLink(createClient({
    url: process.env.NEXT_PUBLIC_HASURA_WS_URL,
  })) : null

  const httpLink = createUploadLink({
    uri: process.env.NEXT_PUBLIC_HASURA_URL,
    fetchOptions: {
      mode: "cors",
    },
    credentials: "include",
    fetch: enhancedFetch,
    headers: {
      ...headers,
      "x-hasura-app-code": process.env.NEXT_PUBLIC_APP_CODE,
    },
  })

  const link = typeof window !== "undefined" ? ApolloLink.split(
        // Split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query)
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          )
        },
        wsLink,
        httpLink,
      )
    : httpLink

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            consola.info(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          )
        if (networkError)
          consola.info(`[Network error]: ${networkError}. Backend is unreachable. Is it running?`)
      }),
      link,
    ]),
    cache: new InMemoryCache(),
    defaultOptions,
  })
}

type InitialState = NormalizedCacheObject | undefined

interface IInitializeApollo {
  headers?: IncomingHttpHeaders | null
  initialState?: InitialState | null
}

export const initializeApollo = (
  { headers, initialState }: IInitializeApollo = {
    headers: null,
    initialState: null,
  },
) => {
  const apolloClients = apolloClient || createApolloClient(headers)
  if (initialState) {
    const existingCache = apolloClients.extract()

    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    })

    apolloClients.cache.restore(data)
  }

  if (typeof window === "undefined") return apolloClients
  if (!apolloClient) apolloClient = apolloClients

  return apolloClients
}

export function useApollo(pageProps: AppProps["pageProps"]) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo({ initialState: state }), [state])
  return store
}