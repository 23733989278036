import { isMobile } from "react-device-detect"
import { useRouter } from "next/router"
import BottomNavigation from "../BottomNavigation"
import Header from "../Header"
import DrawerDefault from "../DefaultLayout/DrawerDefault"

function OnboardingLayout({ children, handleLogout }) {
  const router = useRouter()

  return (
    <div className="relative min-h-screen">
      <div>
        <style>
          {`
            .svelte-1xkyjie {
              display: none;
            }
          `}
        </style>
        <Header handleLogout={handleLogout} />
        <div>
          <DrawerDefault handleLogout={handleLogout} />
        </div>
        <div>{children}</div>
      </div>
      <div className="block sm:hidden">
        <BottomNavigation />
      </div>
    </div>
  )
}

export default OnboardingLayout
