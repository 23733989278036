import Icon from "lib/components/Icons"
import WrapperAnimate from "@components/shared-components/WrapperAnimate/WrapperAnimateShow"
import Modal from "react-modal"

Modal.setAppElement("#root")

interface CardModalProps {
  children: React.ReactNode
  closeModal?: () => void
  isModalOpen?: boolean
  positionCloseX?: string
  customIconCloseX?: string
  positionTopCloseX?: string
  overlayClassName?: string
  withArrow?: boolean
}

const customStyles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "unset",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    overflow: "hidden",
    padding: 0,
    border: "0px",
  },
}

function ContainerModalCustom({
  children,
  closeModal,
  isModalOpen = false,
  positionCloseX = "right-0",
  customIconCloseX = "xButton",
  positionTopCloseX = "",
  overlayClassName = "bg-transparent fixed inset-0 z-40 shadow-lg",
  withArrow = true,
}: CardModalProps) {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="homepage banner"
      style={customStyles}
      overlayClassName={overlayClassName}>
      <WrapperAnimate type="route">
        {withArrow && (
          <Icon
            iconName={customIconCloseX}
            color="black"
            styles={`absolute bg-white z-20 ${positionCloseX} ${positionTopCloseX} ml-4 mt-2.5 border-0 hover:cursor-pointer hover:bg-gray-100 rounded-full`}
            onClick={() => {
              setTimeout(() => {
                closeModal()
              }, 300)
            }}
          />
        )}
        {children}
      </WrapperAnimate>
    </Modal>
  )
}

export default ContainerModalCustom
