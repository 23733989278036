import { gtmEvent } from "lib/gtm"
import { colorType } from "lib/interfaces"
import Icons from "lib/components/Icons"
import Text, { weightProps } from "lib/components/Text"
import Link from "next/link"
import { useMemo, useRef, useState } from "react"

type ProgramCategories = {
  id: any
  category_name: string
  slug?: string
}

type CategoriesQuery = {
  parent_id: any
  slug?: string
  priority: number
  category_name: string
  category_description?: string
  id: any

  categories: ProgramCategories[]
}
export type keySelectCategory = "category_name" | "category_name_indo"
export interface CategorySelectorProps {
  color?: colorType
  weight?: weightProps
  isHeader?: boolean
  categories?: CategoriesQuery[]
  language: "ID" | "EN"
  title?: string
  topCategories?: string
  allCategory?: string
  setActiveDropdown,
  activeDropdown,
  showMarquee: boolean,
}

function CategorySelector({
  isHeader = false,
  color = "light",
  weight = "normal",
  categories = [],
  language = "ID",
  title = "Training",
  topCategories = "Top Categories",
  allCategory = "All Categories",
  setActiveDropdown,
  activeDropdown,
  showMarquee,
}: CategorySelectorProps) {
  const [selectedCategory, setSelectedCategory] = useState<string>("")
  const [keySelectedCategory, setKeySelectedCategory] = useState<keySelectCategory>("category_name")
  const categoryData = categories.filter((category) => !category.parent_id)
  const subCategoryData = categories.filter((category) => category.parent_id)
  const priorityCategoryData = subCategoryData.sort((a, b) => b.priority - a.priority)
  const topCategoryData = priorityCategoryData.slice(0, 10)

  const selectedSubCategoryData =
    categories.find((category) => category.id === selectedCategory)?.categories || []

  const refCategory: any = useRef()
  let animationType = "opacity-0"
  let animationIcon = "transform rotate-0 duration-200"
  let hexColor = isHeader ? "#FFFFFF" : "#000"

  if (activeDropdown === "category") {
    animationType = "opacity-100"
    animationIcon = "transform rotate-180 duration-200"
  }

  if (activeDropdown !== "category") {
    animationType = "hidden"
  }

  switch (color) {
    case "primary":
      hexColor = "#286DCC"
      break
    case "secondary":
      hexColor = "#00B49B"
      break
    default:
      break
  }
  
  useMemo(() => {
    setKeySelectedCategory("category_name")
    if (language === "ID") {
      setKeySelectedCategory("category_name_indo")
    }
  }, [language])

  const handleSelectCategory = (categoryId) => {
    setSelectedCategory(categoryId)
  }

  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        className="flex flex-row gap-2 items-center"
        onFocus={() => setActiveDropdown("category")}
        onMouseOver={() => setActiveDropdown("category")}>
        <Text weight={weight} color={isHeader ? color : "dark"}>
          {isHeader ? title : allCategory}
        </Text>
        <Icons iconName="expand-more" styles={animationIcon} color={hexColor} />
      </div>
      <div
        ref={refCategory}
        className={`transform ease-in-out duration-500 ${animationType} absolute mx-auto inset-0 ${showMarquee ? "top-24" : "top-16"} z-20 ${
          activeDropdown === "category" ? "hover:block" : "hidden"
        }`}
        onMouseLeave={() => setActiveDropdown(null)}>
        <div className="bg-white rounded-lg shadow-search flex flex-row h-96 w-percent-80 mx-auto">
          <div className="w-1/3 md:w-1/4 border-r-2 px-2 py-6 overflow-x-auto flex flex-col gap-y-2">
            <Link href="/programs/all" passHref legacyBehavior>
              <div
                key="all"
                className="px-2 py-2 rounded-md hover:bg-tag cursor-pointer"
                role="button"
                tabIndex={0}
                onMouseEnter={() => {
                  handleSelectCategory(null)
                }}
                onClick={() => {
                  setActiveDropdown("category")
                  handleSelectCategory(null)
                  gtmEvent("ce_header_menu_click", {
                    menu_name: "All Categories",
                  })
                }}>
                <Text weight="medium">{allCategory}</Text>
              </div>
            </Link>
            {categoryData.map((category) => {
              let styles = ""
              if (selectedCategory === category.id) {
                styles = "bg-tag"
              }

              if (category.categories.length > 0) {
                return (
                  <div
                    key={category.id}
                    className={`grid grid-cols-6 items-center gap-x-2 px-2 py-2 rounded-md hover:bg-tag cursor-pointer ${styles}`}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      gtmEvent("ce_header_menu_click", {
                        menu_name: category.category_name,
                      })
                      setActiveDropdown(null)
                      window.location.href = `/programs/${category.slug}`
                    }}
                    onMouseEnter={() => {
                      handleSelectCategory(category.id)
                    }}>
                    <Text weight="medium" customClass="col-span-5">
                      {category[keySelectedCategory] || category.category_name}
                    </Text>
                    <Icons iconName="arrow-right" width={24} height={24} />
                  </div>
                )
              }
              return (
                <Link key={category.slug} href={`/programs/${category.slug}`} passHref legacyBehavior>
                  <div
                    key="all"
                    className="px-2 py-2 rounded-md hover:bg-tag cursor-pointer"
                    role="button"
                    tabIndex={0}
                    onMouseEnter={() => {
                      handleSelectCategory(null)
                    }}
                    onClick={() => {
                      gtmEvent("ce_header_menu_click", {
                        menu_name: category.category_name,
                      })
                      setActiveDropdown(null)
                      handleSelectCategory(null)
                    }}>
                    <Text weight="medium">
                      {category[keySelectedCategory] || category.category_name}
                    </Text>
                  </div>
                </Link>
              )
            })}
          </div>
          <div className="w-2/3 md:w-3/4 p-6 overflow-x-auto bg-gray-100">
            <div className="h-fit flex flex-wrap justify-start items-start gap-y-2">
              {selectedSubCategoryData.length > 0 ? (
                <>
                  {selectedSubCategoryData.map((item) => (
                    <div
                      key={item.id}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        gtmEvent("ce_header_menu_click", {
                          menu_name: item.category_name,
                        })
                        setActiveDropdown(null)
                        handleSelectCategory(null)
                      }}
                      className="px-3 py-2 border-l-4 border-gray-300 hover:bg-tag cursor-pointer w-1/2 md:w-1/3">
                      <Link href={`/programs/${item.slug}`} passHref legacyBehavior>
                        <a>
                          <Text customClass="cursor-pointer">
                            {item[keySelectedCategory] || item.category_name}
                          </Text>
                        </a>
                      </Link>
                    </div>
                  ))}
                </>
              ) : (
                <div>
                  <div>
                    <Text weight="bold" size="large">
                      {topCategories}
                    </Text>
                  </div>
                  <div className="flex flex-wrap gap-4 mt-4">
                    {topCategoryData.map((cat) => (
                      <div
                        key={cat.slug}
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          gtmEvent("ce_header_menu_click", {
                            menu_name: cat.category_name,
                          })
                          setActiveDropdown(null)
                          handleSelectCategory(null)
                        }}>
                        <a href={`/programs/${cat.slug}`}>
                          <div className="bg-white px-4 py-2 rounded-lg">
                            <Text customClass="cursor-pointer">
                              {cat[keySelectedCategory] || cat.category_name}
                            </Text>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategorySelector
