import Modal from "lib/components/Modal"
import Text from "lib/components/Text"
import { useRouter } from "next/router"
import Icons from "lib/components/Icons"
import Link from "next/link"
import { useContext } from "react"
import { LanguageContext } from "context/LanguageContext"
import { checkStringContain } from "lib/helpers"

function JoinUsModal({ onClose }) {
  const { useTranslation: t } = useContext(LanguageContext)
  const router = useRouter()
  return (
    <Modal padding="extra-large" onClose={onClose}>
      <div>
        <Text alignText="center" weight="bold">
          {t("join_us")}
        </Text>
        <div className="my-4 z">
          <Text color="subtitle" alignText="center">
            {t("to_begin_this_journey")}
          </Text>
        </div>
        <div className="mt-8">
          <div
            role="button"
            onClick={() => {
              if (checkStringContain(router.asPath, ["/blog", "/inhouse"])) {
                router.push(`/register?r=${encodeURIComponent(router.asPath)}`)
              } else {
                router.push("/register")
              }
              if (onClose) {
                onClose()
              }
            }}
            tabIndex={0}
            className="shadow rounded-lg p-4 h-36 sm:h-24 block sm:flex justify-between cursor-pointer border border-transparent hover:border-primary hover:bg-blue-50">
            <div className="w-1/5 hidden sm:flex justify-center items-center">
              <div className="p-2 rounded-full bg-primary">
                <Icons iconName="user" color="#ffffff" />
              </div>
            </div>
            <div className="w-full">
              <Text weight="bold">{t("individual")}</Text>
              <Text size="small" color="muted">
                {t("for_you_who_want_to_enroll_in_public_classes")}
              </Text>
            </div>
            <div className="w-1/5 hidden sm:flex justify-center items-center">
              <Icons iconName="arrow-right" />
            </div>
          </div>

          <div
            role="button"
            onClick={() => {
              router.push("/business#form_leads_section")
              if (onClose) {
                onClose()
              }
            }}
            tabIndex={0}
            className="mt-6 shadow rounded-lg p-4 h-36 sm:h-24 block sm:flex justify-between cursor-pointer border border-transparent hover:border-primary hover:bg-blue-50">
            <div className="w-1/5 hidden sm:flex justify-center items-center">
              <div className="p-2 rounded-full bg-primary">
                <Icons iconName="user" color="#ffffff" />
              </div>
            </div>
            <div className="w-full">
              <Text weight="bold">{t("business")}</Text>
              <Text size="small" color="muted">
                {t("for_you_who_would_like_to_arrange_training")}
              </Text>
            </div>
            <div className="w-1/5 hidden sm:flex justify-center items-center">
              <Icons iconName="arrow-right" />
            </div>
          </div>
        </div>
        <div className="mt-4 border-t pt-4" onClick={onClose} tabIndex={0} role="button">
          <Text color="gray" size="small" alignText="center">
            {t("already_have_an_account?")}{" "}
            <span className="text-primary font-bold">
              <Link href="/login">{t("login")}</Link>
            </span>
          </Text>
        </div>
      </div>
    </Modal>
  )
}

export default JoinUsModal
