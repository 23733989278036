import Image from "next/legacy/image"
import Link from "next/link"

interface HeaderFeedbackProps {
  theme?: "default" | "light"
  withoutBorded ?: boolean
  withoutLogo ?: boolean
}

function HeaderFeedback({
  theme,
  withoutBorded,
  withoutLogo,
}: HeaderFeedbackProps) {
  let backgroundColor = "bg-primary"
  let assetLogo = "/assets/images/logo-large-secondary.png"
  const marginXHeader = "px-4 sm:px-0 sm:mx-auto"
  if (theme === "light") {
    backgroundColor = `bg-white ${withoutBorded ? "" : "shadow-bottom"}`
    assetLogo = "/assets/images/logo-large-primary.png"
  }

  return (
    <div className={`w-full z-10 ${backgroundColor} h-16 sm:h-32 sm:px-16 md:h-20 lg:h-20 xl:h-20 2xl:h-24 max-h-32 fixed`}>
      <div className={`sm:container relative ${marginXHeader} flex justify-between h-full items-center`}>
        <div className="cursor-pointer h-full">
          <div className={`${withoutLogo ? "block sm:hidden h-inherit" : "block h-inherit"}`}>
            <Link href="/" legacyBehavior>
              <a className="flex items-center h-inherit">
                <Image src={assetLogo} alt="Picture of the author" width={148} height={33} />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

HeaderFeedback.defaultProps = {
  theme: "default",
  withoutBorded: false,
  withoutLogo: false,
}

export default HeaderFeedback