import consola from "consola"
import { createContext, ReactNode, useEffect, useReducer } from "react"

export type LanguageType = "EN" | "ID"
interface LanguageContextProps {
  handleChangeLanguage?: (language: LanguageType) => void
  useTranslation?: (language: string) => string
  language: LanguageType
  translations: object
}
const initialStateLanguage: LanguageContextProps = {
  language: "EN",
  translations: {},
}
export const LanguageContext = createContext<LanguageContextProps>(initialStateLanguage)

export const LanguageReducer = (state, action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      }
    case "SET_DATA_LANGUAGE":
      return {
        ...state,
        translations: action.payload,
      }

    default:
      return state
  }
}

interface LanguageProviderProps {
  children: ReactNode
}
export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [state, dispatch] = useReducer(LanguageReducer, initialStateLanguage)

  useEffect(() => {
    let dataLanguageStorage: LanguageType = "EN"

    try {
      dataLanguageStorage = (localStorage.getItem("language") as LanguageType)
    } catch (error) {
      consola.log(error.message)
    }

    dispatch({
      type: "SET_LANGUAGE",
      payload: dataLanguageStorage,
    })
  }, [])

  useEffect(() => {
    const translate = async () => {
      const dataLanguage = await import(`../public/locales/${state.language || "EN"}/translations`)
      dispatch({
        type: "SET_DATA_LANGUAGE",
        payload: dataLanguage,
      })
    }
    translate()
  }, [state.language])

  const handleChangeLanguage = (dataLanguage: LanguageType) => {
    dispatch({
      type: "SET_LANGUAGE",
      payload: dataLanguage,
    })
    localStorage.setItem("language", dataLanguage)
  }

  const useTranslation = (word: string) => {
    if (state.translations) {
      const translated = state.translations[word] || ""
      return translated
    }

    return null
  }

  const languageContextValues = {
    ...state,
    handleChangeLanguage,
    useTranslation,
  }

  return (
    <LanguageContext.Provider value={languageContextValues}>{children}</LanguageContext.Provider>
  )
}
