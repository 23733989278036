import ButtonCloseQiscus from "@components/shared-components/ButtonCloseQiscus"
import useButtonCloseQiscus from "lib/hooks/useButtonCloseQiscus"
import { useRouter } from "next/router"
import HeaderCheckout from "../Header/checkout"
import Header from "../Header"

function CheckoutLayout({ children }) {
  const { closeQiscusApp, isQiscusHidden } = useButtonCloseQiscus()
  const router = useRouter()
  let slugQuery = "PBC"
  let paddingTop = "pt-16"

  if (router.query.slug?.includes("PKC")) {
    slugQuery = "PKC"
  }

  if (slugQuery === "PKC") {
    paddingTop = "pt-0"
  }

  return (
    <div className="relative min-h-screen">
      <style>
        {`
          ${
            isQiscusHidden
              ? "#qismo-widget {display:none} #qiscus-app-button-close { display:none }"
              : ""
          }
          .svelte-1xkyjie {
            display: none;
          }
        `}
      </style>
      <div>
        {slugQuery === "PKC" ? <Header /> : <HeaderCheckout theme="light" />}
        <div className={`${paddingTop} bg-[#F9FAFB]`}>{children}</div>
      </div>
      <ButtonCloseQiscus closeQiscusApp={closeQiscusApp} />
    </div>
  )
}

export default CheckoutLayout
