import { createContext, useReducer, useEffect, useState } from "react"
import useSWR from "swr"

import { GetCategoriesQuery } from "@graphql/program/getCategories"
import fetcher from "lib/fetcher"

export const CategoryReducer = (state, action) => {
  switch (action.type) {
    case "SET_CATEGORIES":
      return {
        ...state,
        data: action.data,
      }
    case "UNSET_CATEGORIES":
      return {
        ...state,
        data: [],
      }
    default:
      return state
  }
}

export interface CategoryContextProps {
  selectedCategory?(uid: number | string): void
  activeCategory?: number | string
  categories?: {
    data: GetCategoriesQuery
  }
}

export const CategoryContext = createContext<CategoryContextProps>({})

export function CategoryProvider({ children }) {
  const initReducer = {
    activeCategory: null,
    data: {
      program_category: [],
    },
  }

  const [activeCategory, setActiveCategory] = useState(null)
  const [categories, dispatch] = useReducer(CategoryReducer, initReducer)
  const { data: categoryList } = useSWR<GetCategoriesQuery>(
    "/api/categories/list_data",
    fetcher,
  )

  useEffect(() => {
    dispatch({
      type: "SET_CATEGORIES",
      data: categoryList,
    })

    return () => {
      dispatch({
        type: "UNSET_CATEGORIES",
      })
      setActiveCategory(null)
    }
  }, [categoryList])

  const selectedCategory = (uid: number | string) => {
    setActiveCategory(uid)
  }

  return (
    <CategoryContext.Provider
      value={{
        activeCategory,
        categories,
        selectedCategory,
      }}>
      {children}
    </CategoryContext.Provider>
  )
}
