import { checkStringContain } from "lib/helpers"
import Icon from "lib/components/Icons"
import { LanguageContext } from "context/LanguageContext"
import { useAuth } from "lib/auth"
import { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"

function BottomNavigation() {
  const { userProfile, isLoggedIn } = useAuth()
  const router = useRouter()
  const { useTranslation: t } = useContext(LanguageContext)

  const [activeRoute, setActiveRoute] = useState("home")

  useEffect(() => {
    let selectedRoute = "home"

    if (router.asPath.includes("my-classes")) {
      localStorage.setItem("active_route", "my-classes")
      selectedRoute = "my-classes"
    }

    if (router.asPath.includes("orders")) {
      localStorage.setItem("active_route", "orders")
      selectedRoute = "orders"
    }

    if (router.asPath.includes("inhouse-proposal")) {
      localStorage.setItem("active_route", "inhouse-proposal")
      selectedRoute = "inhouse-proposal"
    } 

    if (router.asPath.includes("login")) {
      localStorage.setItem("active_route", "login")
      selectedRoute = "login"
    }

    if (router.asPath.includes("settings")) {
      localStorage.setItem("active_route", "settings")
      selectedRoute = "settings"
    }

    setActiveRoute(selectedRoute)
  }, [router.asPath])

  return (
    <div className="fixed bottom-0 left-0 z-30 w-full h-16 bg-white border-t border-gray-200 ">
      <div
        className={`grid h-full max-w-lg ${
          userProfile?.user_role === "ptc-company-admin" ? "grid-cols-5" : "grid-cols-4"
        } mx-auto font-medium`}>
        <button
          type="button"
          className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group"
          onClick={() => {
            if (typeof window !== "undefined") {
              localStorage.setItem("active_route", "home")
            }
            router.push("/")
          }}>
          <Icon iconName="homepage" color={activeRoute === "home" ? "#286DCC" : "#6b7280"} />
          <span
            className={`text-[12px] ${activeRoute === "home" ? "text-primary" : "text-gray-500"}`}>
            {t("home")}
          </span>
        </button>
        {userProfile?.user_role === "ptc-company-admin" && (
          <button
            type="button"
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group"
            onClick={() => {
              if (typeof window !== "undefined") {
                localStorage.setItem("active_route", "inhouse-proposal")
              }
              router.push("/management/inhouse-proposal")
            }}>
            <Icon
              iconName="inhouse-proposal"
              color={activeRoute === "inhouse-proposal" ? "#286DCC" : "#6b7280"}
            />
            <span
              className={`text-[12px] ${
                activeRoute === "inhouse-proposal" ? "text-primary" : "text-gray-500"
              }`}>
              {t("proposal")}
            </span>
          </button>
        )}
        <button
          type="button"
          className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group"
          onClick={() => {
            if (typeof window !== "undefined") {
              localStorage.setItem("active_route", "my-classes")
            }
            router.push("/my-classes")
          }}>
          <Icon iconName="file" color={activeRoute === "my-classes" ? "#286DCC" : "#6b7280"} />
          <span
            className={`text-[12px] ${
              activeRoute === "my-classes" ? "text-primary" : "text-gray-500"
            }`}>
            {t("class")}
          </span>
        </button>
        <button
          type="button"
          className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group"
          onClick={() => {
            if (typeof window !== "undefined") {
              localStorage.setItem("active_route", "orders")
            }
            router.push("/orders")
          }}>
          <Icon iconName="payment" color={activeRoute === "orders" ? "#286DCC" : "#6b7280"} />
          <span
            className={`text-[12px] ${
              activeRoute === "orders" ? "text-primary" : "text-gray-500"
            }`}>
            {t("orders")}
          </span>
        </button>
        {isLoggedIn ? (
          <button
            type="button"
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group"
            onClick={() => {
              if (typeof window !== "undefined") {
                localStorage.setItem("active_route", "settings")
              }
              router.push("/settings")
            }}>
            <Icon iconName="setting" color={activeRoute === "settings" ? "#286DCC" : "#6b7280"} />
            <span
              className={`text-[12px] ${
                activeRoute === "settings" ? "text-primary" : "text-gray-500"
              }`}>
              {t("settings")}
            </span>
          </button>
        ) : (
          <button
            type="button"
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group"
            onClick={() => {
              if (typeof window !== "undefined") {
                localStorage.setItem("active_route", "login")
              }
              if (checkStringContain(router.asPath, ["/inhouse"])) {
                router.push(`/login?r=${encodeURIComponent(router.asPath)}`)
              } else {
                router.push("/login")
              }
            }}>
            <Icon iconName="user" color={activeRoute === "login" ? "#286DCC" : "#6b7280"} />
            <span
              className={`text-[12px] ${
                activeRoute === "login" ? "text-primary" : "text-gray-500"
              }`}>
              {t("login")}
            </span>
          </button>
        )}
      </div>
    </div>
  )
}

export default BottomNavigation
