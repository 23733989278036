import { colorType } from "lib/interfaces"
import Icon from "lib/components/Icons"
import Text from "lib/components/Text"
import { useRef, useState, RefObject } from "react"
import useSelectorLang, { LanguageType } from "./useSelectorLanguage"

interface SelectLangOptionProps {
  selectedLanguage: LanguageType
  onSelectLanguage: () => void
  title: LanguageType
}

interface ShowIconLanguageProps {
  isShowIcon: boolean
}
interface CloseDropdownFunction {
  elementRef: RefObject<HTMLDivElement>
  isDropdownOpen: boolean
}

function ShowIconLanguage({ isShowIcon }: ShowIconLanguageProps) {
  if (isShowIcon) {
    return <Icon iconName="check" color="#6B7280" width={16} />
  }
  return <div className="w-4" />
}

function SelectLanguageOption({
  onSelectLanguage,
  selectedLanguage,
  title,
}: SelectLangOptionProps) {
  return <div
    onClick={onSelectLanguage}
    tabIndex={0}
    role="button"
    className="flex gap-3 pl-3 hover:bg-gray-100 py-1.5">
    <ShowIconLanguage isShowIcon={title === selectedLanguage} />
    <Text customClass="capitalize" color="gray">
      {title}
    </Text>
  </div>
}

interface LanguageOptionProps {
  colorTitleDropdown?: colorType
}
function LanguageOption({ colorTitleDropdown = "light" }: LanguageOptionProps) {
  const { language = "ID", handleChangeLanguage = () => {} } = useSelectorLang()
  const [isShowOption, setIsShowOption] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>()

  let iconDropdownColor = "#fff"

  if (colorTitleDropdown === "primary") {
    iconDropdownColor = "#286ECC"
  }
  /**
   * Closes the dropdown menu if the user is not click over the dropdown menu.
   * @param {CloseDropdownFunction} props - The props of the dropdown menu.
   * @returns None
   */
  const closeDropdown = ({ elementRef, isDropdownOpen }: CloseDropdownFunction) => {
    // close if user click outside the dropdown and isDropdownOpen true
    if (!elementRef.current?.matches(":hover") && isDropdownOpen) {
      setIsShowOption((prevIsShowOption) => !prevIsShowOption)
    }
  }
  return (
    <div className="relative">
      <div
        className="flex gap-2"
        onClick={() => {
          setIsShowOption((prevIsShowOption) => !prevIsShowOption)
        }}
        onBlur={() =>
          closeDropdown({
            elementRef: dropdownRef,
            isDropdownOpen: isShowOption,
          })
        }
        role="button"
        tabIndex={0}>
        <Icon iconName="language" color={iconDropdownColor} />
        <Text color={colorTitleDropdown} customClass="capitalize">
          {language}
        </Text>
      </div>
      {isShowOption && (
        <div
          ref={dropdownRef}
          className="rounded bg-white shadow-lg absolute py-4 w-44 top-10 right-0 flex flex-col z-20"
          role="button"
          onBlur={() =>
            closeDropdown({
              elementRef: dropdownRef,
              isDropdownOpen: isShowOption,
            })
          }
          tabIndex={0}>
          <SelectLanguageOption
            onSelectLanguage={() => {
              handleChangeLanguage("ID")
              setIsShowOption((prevIsShowOption) => !prevIsShowOption)
            }}
            selectedLanguage={language}
            title="ID"
          />
          <SelectLanguageOption
            onSelectLanguage={() => {
              handleChangeLanguage("EN")
              setIsShowOption((prevIsShowOption) => !prevIsShowOption)
            }}
            selectedLanguage={language}
            title="EN"
          />
        </div>
      )}
    </div>
  )
}

export default LanguageOption
