import { createContext, ReactNode, useReducer } from "react"

export interface errorItem {
  message: string,
  ttl: ReactNode
}

export interface errorReducerState {
  mainError: boolean,
  mainErrorMessage: string,
  errors: errorItem[],
}


export interface errorContextProps extends errorReducerState {
  setMainError?(message: string): void
  unsetMainError?(): void
  setErrors?(message: string): void
}

const defaultReducerContext = {
  mainError: false,
  mainErrorMessage: "",
  errors: [],
}


export const ErrorReducer = (state: errorReducerState, action) => {
  switch (action.type) {
    case "SET_MAIN_ERROR":
      return {
        ...state,
        mainError: true,
        mainErrorMessage: action.message,
      }
    case "UNSET_MAIN_ERROR":
      return {
        ...state,
        mainError: false,
        mainErrorMessage: "",
      }
    case "SET_ERRORS":
      return {
        ...state,
        errors: [...state.errors, action.data],
      }
    case "UNSET_ERRORS":
      return {
        ...state,
      }
    default:
      return state
  }
}

export const ErrorContext = createContext<errorContextProps>(defaultReducerContext)

export function ErrorProvider({ children }) {

  const [state, dispatch] = useReducer(ErrorReducer, defaultReducerContext)

  const setMainError = (message = "") => {
    dispatch({
      type: "SET_MAIN_ERROR",
      message,
    })
  }

  const unsetMainError = () => {
    dispatch({
      type: "UNSET_MAIN_ERROR",
    })
  }

  const setErrors = (message = "") => {
    dispatch({
      type: "SET_ERRORS",
      data: {
        message,
        ttl: Date.now(),
      },
    })
  }

  const unsetErrors = (errorId) => {
    dispatch({
      type: "SET_ERRORS",
      errorId,
    })
  }

  return (
    <ErrorContext.Provider
      value={{
        errors: state.errors,
        mainError: state.mainError,
        mainErrorMessage: state.mainErrorMessage,
        setMainError,
        unsetMainError,
        setErrors,
      }}
    >
      {children}
    </ErrorContext.Provider>
  )
}
