import { createPortal } from "react-dom"
import { useEffect, ReactNode } from "react"
import router from "next/router"
import Text from "../Text"
import Image from "../Image"
import Icon from "../Icons"

export interface ModalProps {
  title?: string
  subtitle?: string
  actionButtons?: ReactNode
  onClose?(): void
  size?: "small" | "medium" | "large" | "extra-large" | "banner" | "popup" | "full-width"
  padding?: "small" | "medium" | "large" | "extra-large" | "none"
  isLoading?: boolean
  alignItem?: "left" | "right" | "center",
  closeIcon?: boolean
  children?: ReactNode
  image?: string
  customClass?:string
  closeIconColor?:string
  imageModal?: ReactNode
  fullWidth?: boolean
  backgroundColor?: "white" | "gray"
  actionButtonPosition?: "center" | "right"
  borderActionButton?: boolean
}

const Modal = ({
  title,
  subtitle,
  size = "medium",
  image,
  onClose,
  actionButtons,
  padding,
  closeIcon = true,
  alignItem = "center",
  children,
  customClass = "",
  closeIconColor,
  imageModal,
  fullWidth = false,
  backgroundColor = "white",
  actionButtonPosition = "center",
  borderActionButton = false,
}: ModalProps) => {
  let container
  let maxWidth = "600px"
  let paddingModal = "px-4 py-2"
  let alignContent = "items-center"
  let widthModal = "calc(100% - 64px)"
  let backgroundColorModal = "bg-white"

  if (backgroundColor === "gray") {
    backgroundColorModal = "bg-gray-100"
  }

  let positionAction = "justify-center"
  if (actionButtonPosition === "right") {
    positionAction = "justify-end"
  }

  const rootContainer = document.createElement("div")
  rootContainer.className = "modal-open"
  const parentElem = document.querySelector("#__next")
  if (typeof window !== "undefined") {
    parentElem.appendChild(rootContainer)
    container = rootContainer
  }

  document.body.style.overflow = "hidden"
  
  useEffect(
    () => () => {
      parentElem.removeChild(rootContainer)
      if (actionButtons) {
        document.body.style.overflow = "scroll"
      }
    },
    [parentElem, rootContainer],
  )

  useEffect(() => {
    router.events.on("routeChangeComplete", () => {
      document.body.style.overflow = "scroll"
    })
    return () => {
      router.events.off("routeChangeComplete", () => {
        document.body.style.overflow = "scroll"
      })
    }
  }, [router.events])

  switch (alignItem) {
    case "left":
      alignContent = "items-start"
      break
    case "right":
      alignContent = "items-end"
      break
    default:
      break
  }

  switch (size) {
    case "small":
      maxWidth = "400px"
      break
    case "large":
      maxWidth = "700px"
      break
      case "banner":
        maxWidth = "900px"
        break
    case "extra-large":
      maxWidth = "1024px"
      break
    case "full-width":
      maxWidth = "80%"
      break
    case "popup":
      maxWidth = "70vw"
      break
    default:
      break
  }

  switch (padding) {
    case "small":
      paddingModal = "px-2 py-2"
      break
    case "large":
      paddingModal = "px-6 py-4"
      break
    case "extra-large":
      paddingModal = "px-8 py-6"
      break
    case "none":
      paddingModal = "p-0"
      break
    default:
      break
  }

  if (fullWidth) {
    widthModal = "100%"
  }

  function closeModal() {
    onClose()
    document.body.style.overflow = "scroll"
  }

  const element = (
    <div className="transition-all duration-500 ease-in-out inset-0 z-30 fixed flex justify-center items-center p-4">
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        aria-label="backdrop"
        role="button"
        tabIndex={-1}
        onClick={closeModal}
      />
      {imageModal ?
      <>
        {imageModal}
      </>
      :
      <div
        className={`relative bg-white rounded-lg overflow-auto max-h-screen cursor-default ${customClass}`}
        style={{ width: widthModal, maxWidth: `${maxWidth}` }}
      >
        <div className={`flex flex-col ${alignContent} ${backgroundColorModal} pt-2`}>
          <div className={`z-10 ${title ? paddingModal : "p-0"} rounded-lg`}>
            {image && (
              <div className="flex justify-center mt-4">
                <Image src={image} alt="Modal Image" width={200} height={120} />
              </div>
            )}
            <div className="relative">
              <Text weight="semibold">{title}</Text>
            </div>
            {subtitle && (
              <div className="relative">
                <Text type="body" color="gray">
                  {subtitle}
                </Text>
              </div>
            )}
            {closeIcon && (
              <div className="absolute top-3 right-3 cursor-pointer">
                <Icon
                  onClick={closeModal}
                  iconName="xButton"
                  width="25px"
                  height="25px"
                  color={`${closeIconColor || "black"}`}
                />
              </div>
            )}
          </div>
          <div className={`justify-center w-full ${paddingModal} bg-white`}>{children}</div>
        </div>
        {actionButtons && (
          <div className={`flex items-center ${positionAction} w-full ${paddingModal} ${borderActionButton ? "border-t" : ""} pb-4`}>
            {actionButtons}
          </div>
        )}
      </div>
      }
    </div>
  )

  return createPortal(element, container)
}

export default Modal