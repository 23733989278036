import { useEffect, useRef, useState } from "react"

import Button from "lib/components/Button"
import Icons from "lib/components/Icons"

export type IconColorType = "white" | "black" | "primary"

export interface DropdownProps {
  children: React.ReactNode
  title: React.ReactNode
  withoutBackground?: boolean
  colorButton?: "white" | "success" | "primary"
  iconColor?: IconColorType
  fullWidth?: boolean
  alignText?: "center" | "right" | "left"
  pathName?: string
  buttonGap?: "small" | "no-gap"
  showIconExpand?: boolean
}

function Dropdown({
  title,
  children,
  colorButton = "white",
  iconColor,
  withoutBackground,
  fullWidth = false,
  alignText = "center",
  buttonGap = "small",
  pathName,
  showIconExpand = true,
}: DropdownProps) {
  const [isOpen, setOpen] = useState(false)
  let iconColorItem = "#286DCC"

  if (colorButton !== "white") {
    iconColorItem = "#ffffff"
  }

  if (iconColor) {
    iconColorItem = iconColor
  }

  const refChildren = useRef<HTMLDivElement>()
  let animationType = "opacity-0"
  let animationIcon = "rotate-0"
  if (isOpen) {
    animationType = "opacity-100"
    animationIcon = "rotate-180"
  }

  useEffect(() => {
    if (pathName) {
      setOpen(false)
    }
  }, [pathName])

  return (
    <div
      className="dropdown-wrapper relative z-20"
      onBlur={() => {
        if (!refChildren.current.matches(":hover") && isOpen) {
          setOpen(false)
        }
      }}
      role="button"
      tabIndex={0}>
      <Button
        variant={withoutBackground ? "text" : "contained"}
        color={colorButton}
        align={alignText}
        customClass={`${fullWidth && "w-full"} transition duration-300`}
        fontWeight="normal"
        size="large"
        fullWidth
        justifyContent="center"
        buttonGap={buttonGap}
        onClick={() => {
          refChildren.current.focus()
          setOpen(!isOpen)
        }}
        letterSpacing="small">
        {title}
        {showIconExpand &&
          <Icons
            iconName="expand-more"
            styles={`transform duration-200 ${animationIcon}`}
            color={iconColorItem}
          />
        }
      </Button>
      <div
        ref={refChildren}
        role="button"
        tabIndex={0}
        onClick={() => setOpen(!isOpen)}
        className={`transform duration-500 ease-in-out ${animationType} dropdown-menu bg-white mt-4 rounded-lg absolute z-20 shadow-xl w-full`}>
        {isOpen && children}
      </div>
    </div>
  )
}

export default Dropdown