import Icon from "lib/components/Icons"
import Text from "lib/components/Text"

export interface InputSearchProps {
  inline?: boolean
  label?: string
  placeholder: string
  onChange?(object): void
  value?: string | number | string[]
  name: string
  disabled?: boolean
  maxLength?: number
  success?: boolean
  error?: boolean
  message?: string
  onSearch?: () => void
  onlyBorderBottom?: boolean
  showIconSearch?: boolean
  roundedType?: "small" | "default" | "medium" | "large" | "extra-large"
}

function InputSearch({
  inline,
  label,
  placeholder,
  onChange,
  value,
  name,
  disabled,
  maxLength,
  success,
  error,
  message,
  onlyBorderBottom = false,
  showIconSearch = true,
  onSearch,
  roundedType,
}: InputSearchProps) {
  let borderColor = "border-[#D1D5DB]"
  let borderFocus = "border-gray-400"
  let iconType = ""
  let iconColor = "gray"
  let inlineStyle = "flex-col"
  let labelStyle = "mb-2"
  let borderStyle = "border"
  let roundedTypeStyle = "rounded-sm"

  if (onlyBorderBottom) {
    borderStyle = "border-b"
  }

  if (inline) {
    inlineStyle = "flex-row items-center"
    labelStyle = "mr-2"
  }

  if (success) {
    iconType = "check"
    iconColor = "green"
  }

  if (error) {
    borderColor = "border-red-500"
    borderFocus = "border-red-500"
    iconType = "error"
    iconColor = "red"
  }

  let iconStyle = "right-2 top-1"
  let paddingRight = "pr-12"

  if (iconType) {
    iconStyle = "right-4 top-1"
    paddingRight = "pr-12"
  }

  switch (roundedType) {
    case "small":
      roundedTypeStyle = "rounded-sm"
      break
    case "default":
      roundedTypeStyle = "rounded"
      break
    case "medium":
      roundedTypeStyle = "rounded-md"
      break
    case "large":
      roundedTypeStyle = "rounded-lg"
      break
    case "extra-large":
      roundedTypeStyle = "rounded-xl"
    break
    default:
      break
  }

  return (
    <div className={`flex ${inlineStyle}`}>
      {label && (
        <div className={labelStyle}>
          <Text type="small-body" color="gray">
            {label}
          </Text>
        </div>
      )}
      <div className="relative flex-1">
        <input
          className={`w-full ${roundedTypeStyle} ${borderStyle} ${borderColor} p-[0.45rem] px-3.5 ${paddingRight} focus:outline-none focus:${borderFocus} placeholder:text-[#808080] placeholder:text-sm`}
          placeholder={placeholder}
          onChange={(event) => onChange(event)}
          value={value}
          name={name}
          disabled={disabled}
          type="text"
          maxLength={maxLength}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              onSearch()
            }
          }}
        />

        {iconType && (
          <div className="absolute right-2 top-2 cursor-pointer">
            <Icon iconName={iconType} width={24} height={24} color={iconColor} />
          </div>
        )}

        {showIconSearch &&
          <div
            role="button"
            tabIndex={0}
            className={`absolute ${iconStyle} cursor-pointer`}
            onClick={onSearch}>
            <Icon iconName="search" width={30} height={30} color="#959EAD" />
          </div>
        }
      </div>
      {error && (
        <div className="mt-1">
          <Text type="small-body" color="danger">
            {message}
          </Text>
        </div>
      )}
    </div>
  )
}

export default InputSearch
