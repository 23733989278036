import { CreateOrderMutationVariables } from "@graphql/order/create"
import { useRouter } from "next/router"
import { createContext, useState, useContext, useEffect, ReactNode } from "react"
import { toast } from "react-toastify"
import consola from "consola"
import { useAuth } from "./auth"

export type CheckoutData = CreateOrderMutationVariables

type CheckoutContextType = {
  checkoutData?: CheckoutData
  setCheckoutData: (checkoutData?: CheckoutData) => void
  removeCheckoutData: () => void
}

const initialCheckoutData: CheckoutData = {
  class_id: "",
  payment_method: "bank_transfer",
  ticket_qty: 1,
  type_program: "public",
  participants: [],
  total_discount_coupon: 0,
  coupon_code: null,
}

const CheckoutContext = createContext<CheckoutContextType>({
  checkoutData: initialCheckoutData,
  setCheckoutData: () => undefined,
  removeCheckoutData: () => undefined,
})

type CheckoutProviderProps = {
  children: ReactNode
}

/**
 * Checkout Provider to manage data from checkout process
 */
export const CheckoutProvider = ({ children }: CheckoutProviderProps) => {
  const router = useRouter()
  const { userProfile } = useAuth()
  const [checkoutData, setCheckoutData] = useState<CheckoutData>(initialCheckoutData)

  const setCheckoutDataStorage = (data: CheckoutData) => {
    localStorage.setItem("checkoutData", JSON.stringify(data))
    setCheckoutData(data)
  }

  const getCheckoutData = () => {
    try {
      const data = localStorage.getItem("checkoutData")
      if (data) {
        setCheckoutData(JSON.parse(data))
      }
    } catch (error) {
      consola.log(error.message)
    }
  }

  const removeCheckoutData = () => {
    setCheckoutData(initialCheckoutData)
    localStorage.removeItem("checkoutData")
  }

  useEffect(() => {
    getCheckoutData()
  }, [])

  useEffect(() => {
    // redirect to home when user not verified
    if (userProfile && !userProfile?.is_verified && router.pathname.includes("checkout")) {
      router.replace("/")
      toast.error("Oops: Verify your account first before checkout")
    }
  }, [userProfile, router])

  return (
    <CheckoutContext.Provider
      value={{
        checkoutData,
        setCheckoutData: setCheckoutDataStorage,
        removeCheckoutData,
      }}>
      {children}
    </CheckoutContext.Provider>
  )
}

/**
 * Checkout custom hooks
 */
export const useCheckout = () => {
  const context = useContext(CheckoutContext)
  if (context === undefined) {
    throw new Error("useCheckout must be used inside CheckoutProvider")
  }
  return context
}
