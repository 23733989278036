import { createContext, useContext, useState, useEffect, useCallback } from "react"
import fetcher from "lib/fetcher"
import { useRouter } from "next/router"
import { UserRegisterMutationVariables } from "@graphql/auth/userRegister"
import { UserEmailCheckMutationVariables } from "@graphql/auth/userEmailCheck"
import { toast } from "react-toastify"
import moment from "moment"
import { LanguageContext } from "context/LanguageContext"

export type UserProfileType = {
  session_id?: any
  session?: any
  company_id?: string
  company_name?: string
  email?: string
  first_name?: string
  is_verified?: boolean
  last_name?: string
  user_id?: string
  user_role?: string
  phone?: string
  job_title?: string
  session_expiry?: number
  hs?: string
}

export interface UserType extends UserProfileType {
  jwt_expiry?: number
  jwt_token?: string
  session?: string
}

type CheckEmailResponse = {
  credential_exist: boolean
}

type UserLoginParams = {
  /**
   * Login username
   */
  username: string
  /**
   * Login password
   */
  password: string
  /**
   * Login type
   */
  loginType?: string
}

type AuthContextType = {
  /**
   * User login status
   * @default false
   */
  isLoggedIn: boolean
  /**
   * User profile data
   */
  userProfile?: UserProfileType
  /**
   * User login function
   */
  handleUserLogin: (params: UserLoginParams) => Promise<UserType>
  /**
   * User check email function
   */
  handleCheckEmail: (params: UserEmailCheckMutationVariables) => Promise<CheckEmailResponse>
  /**
   * User logout function
   */
  handleUserLogout: () => Promise<any>
  /**
   * User profile function
   */
  handleUserProfile: () => Promise<UserProfileType>
  /**
   * Redirect private page function when user unauthorized
   */
  handleRedirectPrivatePage: () => void
  /**
   * User register function
   */
  handleUserRegister: (params: UserRegisterMutationVariables) => Promise<UserType>
  /**
   * User verify function
   */
  handleUserVerify: () => Promise<any>
}

/**
 * Auth context
 */
const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  handleUserLogin: () => null,
  handleCheckEmail: () => null,
  handleUserLogout: () => null,
  handleUserProfile: () => null,
  handleRedirectPrivatePage: () => null,
  handleUserRegister: () => null,
  handleUserVerify: () => null,
})

/**
 * Auth provider
 */
const AuthProvider = ({ children, pageProps }:any) => {
  const router = useRouter()
  const { useTranslation: t } = useContext(LanguageContext)
  const [userProfile, setUserProfile] = useState<UserProfileType | undefined>(undefined)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const privatePage = !!pageProps.privatePage

  /**
   * Handle get user profile
   */
  const handleUserProfile = useCallback(
    () =>
      new Promise<UserProfileType>((resolve, reject) => {
        fetcher("/api/profile", "GET")
          .then((data) => {
            if (data.session_id) {
              const userData = localStorage.getItem("userdata")
              const parseData = JSON.parse(userData)
              if (parseData?.session) {
                setUserProfile(data)
                setIsLoggedIn(true)
              } else {
                setIsLoggedIn(false)
              }
            } else {
              setIsLoggedIn(false)
            }
            resolve(data)
          })
          .catch((err) => {
            if (err.message === "Unauthorized") {
              const { asPath } = router
              let redirectPath = asPath
              if (privatePage) {
                redirectPath = `/login?r=${encodeURIComponent(asPath)}`
              }
              router.replace(redirectPath)
              reject(err)
            }
          })
      }),
    [],
  )

  /**
   * Handle user login
   */
  const handleUserLogin = (params: UserLoginParams) =>
    new Promise<UserType>((resolve, reject) => {
      fetcher("/api/login", "POST", params)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })

  /**
   * Handle check email
   */
  const handleCheckEmail = (params: UserEmailCheckMutationVariables) =>
    new Promise<CheckEmailResponse>((resolve, reject) => {
      fetcher("/api/auth/email-check", "POST", params)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })

  /**
   * Handle user logout
   */
  const handleUserLogout = async () =>
    new Promise<any>((resolve, reject) => {
      fetcher("/api/logout", "POST")
        .then((data) => {
          setUserProfile(undefined)
          setIsLoggedIn(false)
          localStorage.clear()
          resolve(data)
        })
        .catch((err) => {
          toast.error(err)
          reject(err)
        })
    })

  /**
   * Handle user register
   */
  const handleUserRegister = (params: UserRegisterMutationVariables) =>
    new Promise<UserType>((resolve, reject) => {
      fetcher("/api/signup", "POST", params)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })

  /**
   * Handle redirect private page
   */
  const handleRedirectPrivatePage = useCallback(() => {
    if (privatePage) {
      const userData = localStorage.getItem("userdata")
      const parseData = JSON.parse(userData)
      if (!parseData) {
        setIsLoggedIn(false)
        const { asPath } = router
        const redirectPath = `/login?r=${encodeURIComponent(asPath)}`
        router.replace(redirectPath)
      }
    }
  }, [privatePage, router])

  const handleUserVerify = () =>
    new Promise((resolve, reject) => {
      const errorMessage = "Oops: Something went wrong, please try again"
      if (!userProfile) {
        toast.error(errorMessage)
        return reject(new Error(errorMessage))
      }
      return fetcher("/api/auth/generate-token", "POST", {
        email: userProfile ? userProfile?.email.toLowerCase() : "",
        isForCredential: false,
      })
        .then(() => {
          resolve("success")
          toast.success(t("your_activation_token_has_been_sent_to_your_email"))
          return router.push(`/auth/activate?r=${encodeURIComponent(router.asPath)}`)
        })
        .catch((err) => {
          toast.error(err || errorMessage)
          reject(err)
        })
    })

  useEffect(() => {
    const getProfile = async () => {
      if (!userProfile) {
        try {
          const userData = localStorage.getItem("userdata")
          const parseData = JSON.parse(userData)
          if (parseData) {
            const data = await handleUserProfile()
            const now = new Date()
            const isSessionExpired = moment(now).isAfter(data.session_expiry || now)
            if (isSessionExpired) {
              await handleUserLogout()
              throw new Error("SessionExpired")
            }
          }
        } catch (err) {
          if (err?.message === "SessionExpired" && !router.asPath.includes("login")) {
            await handleUserLogout()
            toast.error("Oops: Your session is expired, please relogin to continue!")
            router.replace(`/login?r=${encodeURIComponent(router.asPath)}`)
          }
        }
      } else {
        const userData = localStorage.getItem("userdata")
        const parseData = JSON.parse(userData)
        if (!parseData) {
          setIsLoggedIn(false)
          setUserProfile(undefined)
          const { asPath } = router
          let redirectPath = asPath
          if (privatePage) {
            redirectPath = `/login?r=${encodeURIComponent(asPath)}`
          }
          router.replace(redirectPath)
        }
      }
    }
    getProfile()
  }, [handleUserProfile, userProfile, isLoggedIn])

  useEffect(() => {
    handleRedirectPrivatePage()
  }, [handleRedirectPrivatePage])

  const results: AuthContextType = {
    isLoggedIn,
    userProfile,
    handleUserLogin,
    handleCheckEmail,
    handleUserLogout,
    handleUserProfile,
    handleRedirectPrivatePage,
    handleUserRegister,
    handleUserVerify,
  }

  return <AuthContext.Provider value={results}>{children}</AuthContext.Provider>
}

/**
 * Auth custom hooks
 */
export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context.isLoggedIn === undefined) {
    throw new Error("useAuth must be used inside AuthProvider")
  }

  return context
}

export default AuthProvider
