import { motion } from "framer-motion"

interface Props {
  children: React.ReactNode
  type?: "route" | "show"
  isLoading?: boolean
  className?: string
  onLeft?: () => void
  onFocus?: () => void
}
const variantsRoute = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
}

const variantsShow = {
  visible: { opacity: 1, scale: 1, y: 0 },
  hidden: { opacity: 0, scale: 1, y: 100 },
}
export default function WrapperAnimateRoute({
  children,
  type,
  isLoading,
  className = "",
  onFocus,
  onLeft,
}: Props) {
  let variantAnimate = {}
  let animate = {}
  let transition = {}
  let exit = ""
  switch (type) {
    case "route":
      variantAnimate = variantsRoute
      animate = "enter"
      transition = { type: "linear" }
      exit = "exit"
      break
    case "show":
      variantAnimate = variantsShow
      animate = isLoading ? "hidden" : "visible"
      transition = { duration: 0.2 }
      break
    default:
      break
  }
  return (
    <motion.div
      id="wrapperAnimate"
      tabIndex={0}
      onBlur={() => {
        onLeft?.()
      }}
      onFocus={() => onFocus?.()}
      variants={variantAnimate}
      initial="hidden"
      animate={animate}
      exit={exit}
      transition={transition}
      className={`${className}`}>
      {children}
    </motion.div>
  )
}
