/* eslint-disable camelcase */
import { createContext, useReducer } from "react"
import QiscusSDK from "qiscus-sdk-core"
import consola from "consola"
import {getEnv} from "lib/config"

const qiscus = new QiscusSDK()
export interface userInformation {
  id: number
  username: string
  avatar_url: string
  email: string
  token: string
  active: boolean
}
export interface roomsOfUserItem {
  id: number
  unique_id: string
  room_type: string
  avatar: string
  name: string
  last_comment_message: string
  last_comment_message_created_at: string
  count_notif: number
}

export interface messageItem {
  id: number
  message: string
  room_type: "single" | "group"
  type?:
    | "text"
    | "account_linking"
    | "button_postback_response"
    | "buttons"
    | "card"
    | "carousel"
    | "contact_person"
    | "custom"
    | "file_attachment"
    | "location"
    | "reply"
    | "text"
  user_avatar_url: string
  username: string
  user_id: number
  comment_before_id: number
  status: string
}

interface qiscusReducerState {
  token: string,
  informationUser: userInformation,
  infoActiveRoom: roomsOfUserItem,
  roomsOfUser: roomsOfUserItem[],
  activeRoomId: string,
  messageOfActiveRoom: messageItem[],
  activeRoomTypingIndicator: string,
}

export interface QiscusContextProps extends qiscusReducerState {
  initQiscus?(): void
  loginQiscus?(username: string, password: string): void
  logoutQiscus?(): void
  loadRoom?(): void
  chatWithOther?(): void
  onTyping?(): void
  notTyping?(): void
  sendTextMessage?(message: string): void
  loadMessage?(roomId: number, last_comment_id?: number, callback?: () => void): void
}


export const QiscusReducer = (state: qiscusReducerState, action) => {
  switch (action.type) {
    case "UNSET_INFO_USER":
      return {
        ...state,
        informationUser: {},
      }
    case "LOGIN_USER":
      return {
        ...state,
        token: action.token,
        informationUser: action.informationUser,
      }
    case "LOGOUT_USER":
      return {
        ...state,
        token: null,
      }
    case "SET_USER_ROOMS":
      return {
        ...state,
        roomsOfUser: action.roomsOfUser,
      }
    case "SET_TYPING_INDICATOR":
      return {
        ...state,
        activeRoomTypingIndicator: action.typingIndicator,
      }
    case "RECEIVE_ACTIVE_MESSAGE": {
      if(state.activeRoomId === action.newMessage.room_id) {
        return {
          ...state,
          messageOfActiveRoom: [...state.messageOfActiveRoom, action.newMessage],
        }
      }
      return {
        ...state,
      }
    }
    case "APPEND_MESSAGE": {
      const messages = [...action.newMessages,...state.messageOfActiveRoom]

      return {
        ...state,
        messageOfActiveRoom: messages,
      }
    }
    case "SET_ACTIVE_MESSAGE":
      return {
        ...state,
        activeRoomId: action.activeRoomId,
        infoActiveRoom: action.infoActiveRoom,
        messageOfActiveRoom: action.messageOfActiveRoom,
      }
    default:
      return state
  }
}

const defaultReducerContext = {
  token: null,
  informationUser: null,
  roomsOfUser: [],
  activeRoomId: null,
  infoActiveRoom: null,
  messageOfActiveRoom: [],
  activeRoomTypingIndicator: "0",
}

export const QiscusContext = createContext<QiscusContextProps>(defaultReducerContext)

export function QiscusProvider({ children }) {

  const [state, dispatch] = useReducer(QiscusReducer, defaultReducerContext)

  let typingTimer: any

  const initQiscus = () => {
    function messageReceive(messages: any) {
      dispatch({
        type: "RECEIVE_ACTIVE_MESSAGE",
        newMessage: messages[0],
      })
    }

    function userTyping(data:any) {
      dispatch({
        type: "SET_TYPING_INDICATOR",
        typingIndicator: data.message,
      })
    }

    qiscus.init({
      AppId: getEnv("NEXT_PUBLIC_QISCUS_APPID"),
      options: {
        newMessagesCallback: messageReceive,
        typingCallback: userTyping,
      },
    })
  }

  const loadMessage = async (roomId: number, last_comment_id = null, callback = null) => {
    try {
      const options = {
        last_comment_id,
        after:false,
        limit: 20,
      }

      const infoRoom = await qiscus.getRoomById(roomId)
      const messages = await qiscus.loadComments(roomId, options)
      
      if (infoRoom && messages) {
        if (last_comment_id) {
          dispatch({
            type: "APPEND_MESSAGE",
            newMessages: messages,
          })
        } else {
          dispatch({
            type: "SET_ACTIVE_MESSAGE",
            activeRoomId: roomId,
            infoActiveRoom: infoRoom,
            messageOfActiveRoom: messages,
          })
        }
          
      }
    } catch (error) {
      consola.error("loadMessage", error)
    } finally {
      if(callback) {
        callback()
      }
    }
  }

  const loadRoom = async () => {
    try {
      const params = {
        page: 1,
        limit: 50,
        show_empty: false,
      }

      const listOfRoom = await qiscus.loadRoomList(params)

      if (listOfRoom) {
        dispatch({
          type: "SET_USER_ROOMS",
          roomsOfUser: listOfRoom,
        })
      }

      // temporary disable
      // if (listOfRoom.length > 0 && listOfRoom[0].id) {
      //   await loadMessage(listOfRoom[0].id)
      // }
    } catch (error) {
      consola.error("loadRoom ", error)
    }
  }

  const loginQiscus = async (username: string, password: string) => {
    try {
      const loginData = await qiscus.setUser(username, password, {})

      if (loginData && loginData.user && loginData.user.token) {
        dispatch({
          type: "LOGIN_USER",
          token: loginData.user.token,
          informationUser: loginData.user,
        })

        await loadRoom()
      }
    } catch (err) {
      consola.error("loginQiscus ", err)
    }
  }

  const sendTextMessage = async (message: string) => {
    try {
      await qiscus.sendComment(state.activeRoomId, message)
    } catch (error) {
      consola.error("sendTextMessage", error)
    }
  }

  const logoutQiscus = () => {
    qiscus.disconnect()
  }

  const notTyping = () => {
    clearTimeout(typingTimer)
    
    qiscus.publishTyping(0)
  }

  const onTyping = () => {
    clearTimeout(typingTimer)
    qiscus.publishTyping(1)    
    typingTimer = setTimeout(() => {
      notTyping()
    }, 5000)
  }
  
  const userContextValues = {
    token: state.token,
    informationUser: state.informationUser,
    infoActiveRoom: state.infoActiveRoom,
    roomsOfUser: state.roomsOfUser,
    activeRoomId: state.activeRoomId,
    messageOfActiveRoom: state.messageOfActiveRoom,
    activeRoomTypingIndicator: state.activeRoomTypingIndicator,
    loginQiscus,
    initQiscus,
    logoutQiscus,
    loadRoom,
    loadMessage,
    sendTextMessage,
    onTyping,
    notTyping,
  }

  return (
    <QiscusContext.Provider value={userContextValues}>
      {children}
    </QiscusContext.Provider>
  )
}
