import { colorType } from "lib/interfaces"
import Icons from "lib/components/Icons"
import Text, { weightProps } from "lib/components/Text"
import { LanguageContext } from "context/LanguageContext"
import { useRouter } from "next/router"
import { useContext, useRef } from "react"

export interface ServicesHeaderProps {
  color?: colorType
  weight?: weightProps
  isHeader?: boolean
  title?: string
  setActiveDropdown,
  activeDropdown,
  showMarquee,
}

function ServicesHeader({
  isHeader = false,
  color = "light",
  weight = "normal",
  title = "Training",
  setActiveDropdown,
  activeDropdown,
  showMarquee,
}: ServicesHeaderProps) {
  const { useTranslation: t } = useContext(LanguageContext)
  const router = useRouter()

  const refServices: any = useRef()
  let animationType = "opacity-0"
  let animationIcon = "transform rotate-0 duration-200"
  let hexColor = isHeader ? "#FFFFFF" : "#000"

  if (activeDropdown === "services") {
    animationType = "opacity-100"
    animationIcon = "transform rotate-180 duration-200"
  }

  if (activeDropdown !== "services") {
    animationType = "hidden"
  }

  switch (color) {
    case "primary":
      hexColor = "#286DCC"
      break
    case "secondary":
      hexColor = "#00B49B"
      break
    default:
      break
  }

  const scrolltoHash = (element_id: string) => {
    const element = document.getElementById(element_id)
    const headerOffset = 55
    const elementPosition = element?.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset
    localStorage.setItem("activeServices", element_id)
    const storageEvent = new Event("storage")
    window.dispatchEvent(storageEvent)
  
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    })
  }

  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        className="flex flex-row gap-2 items-center"
        onFocus={() => setActiveDropdown("services")}
        onMouseOver={() => setActiveDropdown("services")}>
        <Text weight={weight} color={isHeader ? color : "dark"}>
          {title}
        </Text>
        <Icons iconName="expand-more" styles={animationIcon} color={hexColor} />
      </div>
      <div
        ref={refServices}
        className={`transform ease-in-out duration-500 ${animationType} absolute mx-auto inset-0 ${showMarquee ? "top-24" : "top-16"} z-20 ${
          activeDropdown === "services" ? "hover:block" : "hidden"
        }`}
        onMouseLeave={() => setActiveDropdown(null)}>
        <div className="bg-white rounded-b-lg shadow-search flex flex-col h-[210px] w-percent-80 mx-auto">
          <div className="flex w-full p-4 divide-x">
            <a href="/business" className="w-1/2 hover:underline hover:text-primary cursor-pointer">
              <div role="button" tabIndex={0} onClick={() => {
                localStorage.removeItem("activeServices")
                const storageEvent = new Event("storage")
                window.dispatchEvent(storageEvent)
              }}>
                <Text weight="semibold">
                  {t("corporate_solution")}
                </Text>
                <Text customClass="text-sm !text-black text-decoration-inherit">
                  {t("corporate_solution_desc")}
                </Text>
              </div>
            </a>
            <div 
              role="button"
              tabIndex={0}
              onClick={async () => {
                if (router.pathname !== "/") {
                  await router.push("/")
                }
                scrolltoHash("training-organizer-services")
              }}
              className="w-1/2 pl-6 hover:underline hover:text-primary cursor-pointer">
              <Text weight="semibold">
                {t("training_organizer_services")}
              </Text>
              <Text customClass="text-sm !text-black text-decoration-inherit">
              {t("training_organizer_services_desc")}
              </Text>
            </div>
          </div>
          <div className="flex w-full px-4">
            <div 
              role="button"
              tabIndex={0}
              onClick={async () => {
                if (router.pathname !== "/") {
                  await router.push("/")
                }
                scrolltoHash("training-management-services")
              }}
              className="w-1/2 hover:underline hover:text-primary cursor-pointer">
              <Text weight="semibold">
                {t("training_management_services")}
              </Text>
              <Text customClass="text-sm !text-black text-decoration-inherit">
                {t("training_management_services_desc")}
              </Text>
            </div>
            <div className="w-1/2 pl-6" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesHeader
