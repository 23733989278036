import Text from "lib/components/Text"

export interface AlertProps {
  title?: string | number;
  subtitle?: string | number;
  withoutClose?: boolean;
  color?: "primary" | "secondary" |  "danger" | "warning" | "success"
}

function Alert({ title, subtitle, color, withoutClose}: AlertProps) {

  let backgroundColor = "bg-blue-100 border-blue-400 text-blue-700"
  switch (color) {
    case "danger":
      backgroundColor = "bg-red-100 border-red-400 text-red-700"
      break
    case "warning":
      backgroundColor = "bg-yellow-100 border-yellow-400 text-yellow-700"
      break
    case "success":
      backgroundColor = "bg-green-100 border-green-400 text-green-700"
      break
    case "secondary":
      backgroundColor = "bg-secondary border-secondary text-secondary"
      break
    default:
      break
  }
  
  return (
    <div className={`border m-8 px-4 py-3 rounded relative ${backgroundColor}`} role="alert">
      <Text type="headline">
        {title}
      </Text>
      <Text type="body">
        {subtitle}
      </Text>
      {
        !withoutClose
        && (
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg className="fill-current h-6 w-6 text-black" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
          </span>
        )
      }
    </div>
  )}

export default Alert