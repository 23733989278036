import { sizeType, colorType } from "lib/interfaces"

export type weightProps =
  | "weigh"
  | "normal"
  | "medium"
  | "semibold"
  | "bold"
  | "light"
  | "extrabold"
  | "black"
export interface TextProps {
  type?:
    | "large-title"
    | "medium-title"
    | "title"
    | "headline"
    | "subtitle"
    | "heading"
    | "caption"
    | "body"
    | "small-body"
    | "distance"
  alignText?: "left" | "right" | "center" | "justify"
  gutterBottom?: boolean
  children?: React.ReactNode
  fontStyle?: string
  weight?: weightProps
  nowrap?: boolean
  fontFamily?: "roboto" | "poppins"
  customClass?: string
  lineThrough?: boolean
  size?: sizeType
  color?: colorType
}

function Text({
  color,
  type,
  alignText,
  gutterBottom,
  children,
  fontStyle,
  size,
  weight,
  fontFamily,
  nowrap,
  lineThrough,
  customClass = "",
}: TextProps) {
  let fontSizeText = ""
  let typeText = ""
  let whitespace = ""
  let isGutter = "m-0"
  let textColor = "text-current"
  let textAlign = "text-left"
  let textStyle = ""
  let fontWeight = "font-normal"
  let fontFamilyText = ""
  let lineThroughtText = ""

  switch (size) {
    case "ultra-large":
      fontSizeText = "xl:text-2xl 2xl:text-3xl"
      break
    case "extra-large":
      fontSizeText = "xl:text-xl 2xl:text-2xl"
      break
    case "large":
      fontSizeText = "xl:text-lg 2xl:text-xl"
      break
    case "medium":
      fontSizeText = "text-base"
      break
    case "small":
      fontSizeText = "text-sm"
      break
    case "extra-small":
      fontSizeText = "text-xs"
      break
    case "2extra-small":
      fontSizeText = "text-[10px]"
      break
    case "3extra-small":
      fontSizeText = "text-[8px]"
      break
    default:
      fontSizeText = ""
      break
  }

  switch (type) {
    case "large-title":
      fontFamilyText = "font-poppins"
      typeText = "xl:text-large 2xl:text-extraLarge font-bold tracking-medium"
      break
    case "medium-title":
      fontFamilyText = "font-poppins"
      typeText = "xl:text-medium 2xl:text-large font-bold"
      break
    case "title":
      fontFamilyText = "font-poppins"
      typeText = "xl:text-xl 2xl:text-2xl tracking-medium"
      break
    case "headline":
      typeText = "xl:text-lg 2xl:text-xl font-bold"
      break
    case "subtitle":
      typeText = "text-lg"
      break
    case "heading":
      typeText = "text-base font-bold"
      break
    case "caption":
      typeText = "text-base font-light"
      break
    case "body":
      typeText = "text-base"
      break
    case "small-body":
      typeText = "text-sm"
      break
    case "distance":
      typeText = "leading-loose text-default-700 opacity-80"
      break
    default:
      break
  }

  switch (color) {
    case "primary":
      textColor = "text-primary"
      break
    case "primary-light":
      textColor = "text-blue-900"
      break
    case "primary-lightest":
      textColor = "text-blue-700"
      break
    case "secondary":
      textColor = "text-secondary"
      break
    case "inactive":
      textColor = "text-yellow-700"
      break
    case "dark":
      textColor = "text-[#343A40]"
      break
    case "light":
      textColor = "text-white"
      break
    case "gray":
      textColor = "text-gray-500"
      break
    case "gray-light":
      textColor = "text-gray-400"
      break
    case "gray-dark":
      textColor = "text-gray-600"
      break
    case "gray-darker":
      textColor = "text-gray-800"
      break
    case "gray-darkest":
      textColor = "text-gray-900"
      break
    case "danger":
      textColor = "text-danger"
      break
    case "failed":
      textColor = "text-failed"
      break
    case "success":
      textColor = "text-success"
      break
    case "muted":
      textColor = "text-muted"
      break
    case "default":
      textColor = "text-default"
      break
    case "breadcrumbs":
      textColor = "text-breadcrumbs"
      break
    case "sky":
      textColor = "text-[#0A243F]"
      break
    case "subtitle":
      textColor = "text-subtitle"
      break
    default:
      break
  }

  switch (alignText) {
    case "left":
      textAlign = "text-left"
      break
    case "right":
      textAlign = "text-right"
      break
    case "center":
      textAlign = "text-center"
      break
    case "justify":
      textAlign = "text-justify"
      break
    default:
      break
  }

  switch (weight) {
    case "light":
      fontWeight = "font-light"
      break
    case "normal":
      fontWeight = "font-normal"
      break
    case "medium":
      fontWeight = "font-medium"
      break
    case "semibold":
      fontWeight = "font-semibold"
      break
    case "bold":
      fontWeight = "font-bold"
      break
    case "extrabold":
      fontWeight = "font-extrabold"
      break
    case "black":
      fontWeight = "font-black"
      break
    default:
      fontWeight = ""
      break
  }

  switch (fontStyle) {
    case "italic":
      textStyle = "italic"
      break
    case "underline":
      textStyle = "underline"
      break
    case "bold":
      textStyle = "font-bold"
      break
    case "through":
      textStyle = "line-through"
      break
    default:
      break
  }

  if (gutterBottom) {
    switch (type) {
      case "large-title":
      case "title":
        isGutter = "xl:mb-2 2xl:mb-3"
        break
      case "headline":
      case "subtitle":
        isGutter = "xl:mb-1 2xl:mb-2"
        break
      default:
        isGutter = "mb-2"
        break
    }
  }

  if (nowrap) {
    whitespace = "whitespace-nowrap"
  }

  if (lineThrough) {
    lineThroughtText = "line-through"
  }

  if (fontFamily) {
    fontFamilyText = fontFamily
  }

  return (
    <p
      className={`${textStyle} ${whitespace} ${fontSizeText} ${fontFamilyText} ${typeText} ${isGutter} ${textColor} ${textAlign} ${fontWeight} ${customClass} ${lineThroughtText}`}>
      {children}
    </p>
  )
}

export default Text
