import Text from "lib/components/Text"
import useSelectorLang from "./useSelectorLanguage"

const ID = "ID"
const EN = "EN"

type LanguageType = "ID" | "EN"
type Color = "gray" | "light"

const languageCode = {
  "ID": "indonesia",
  "EN": "english",
}

interface FieldLanguageProps {
  language: LanguageType
  langCode: string
}

function FieldLanguage({ language, langCode }: FieldLanguageProps) {
  let customClass = ""
  let color: Color = "gray"

  if (language === langCode) {
    customClass = "bg-primary"
    color = "light"
  }
  return (
    <div>
      <Text
        size="small"
        weight="medium"
        color={color}
        customClass={`${customClass} px-2 transition rounded capitalize`}>
        {languageCode[langCode]}
      </Text>
    </div>
  )
}

function SelectorLanguage() {
  const { handleChangeLanguage = () => {}, language = "ID", setLanguage = () => {} } = useSelectorLang()

  return (
    <div className="flex items-center mt-8">
      <label htmlFor="toggleB" className="flex items-center cursor-pointer">
        <div>
          <div className="flex items-center justify-between bg-gray-200  rounded p-1 ">
            <input
              type="checkbox"
              id="toggleB"
              className="sr-only"
              onClick={() => {
                if (language === ID) {
                  setLanguage(EN)
                  handleChangeLanguage("EN")
                } else {
                  setLanguage(ID)
                  handleChangeLanguage("ID")
                }
              }}
            />
            <FieldLanguage language={language} langCode={ID} />
            <FieldLanguage language={language} langCode={EN} />
          </div>
        </div>
      </label>
    </div>
  )
}

export default SelectorLanguage
