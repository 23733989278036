import consola from "consola"
import { useEffect, useState } from "react"

export default function useButtonCloseQiscus() {
  const [isQiscusHidden, setIsQiscusHidden] = useState<boolean>(false)

  useEffect(() => {
    try {
      const storageQiscussAppStatus = localStorage.getItem("qiscusApp") as "hidden" | null | undefined
      if (storageQiscussAppStatus) {
        setIsQiscusHidden(true)
      } 
    } catch (error) {
      consola.log(error.message)
    }
    
    window.addEventListener("storage", () => {
      setIsQiscusHidden(false)
    })
  }, [])

  const closeQiscusApp = () => {
    setIsQiscusHidden(true)
    localStorage.setItem("qiscusApp", "hidden")
  }

  const openQiscusApp = () => {
    setIsQiscusHidden(false)
    localStorage.removeItem("qiscusApp")
  }

  return {
    closeQiscusApp,
    isQiscusHidden,
    openQiscusApp,
  }
}
