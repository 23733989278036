import axios, { Method } from "axios"

interface HeaderProps {
  "Content-Type": string
  "x-session-id"?: string
  email?: string
}

// eslint-disable-next-line default-param-last
const fetcher = async (url: string, method: Method = "get", data?: any) => {
  try {
    if (!url) {
      throw new Error("URL NOT MATCH")
    }

    const headers: HeaderProps = {
      "Content-Type": "application/json",
    }

    const userData = localStorage.getItem("userdata")
    if (userData) {
      const parseData = JSON.parse(userData)
      if (parseData?.session) {
        headers["x-session-id"] = parseData.session
        if (parseData.email) {
          headers.email = parseData.email
        }
      }
    }

    const filteredHeaders = Object.fromEntries(Object.entries(headers).filter(([_, v]) => v !== undefined))

    const response = await axios.request({
      method,
      headers: filteredHeaders,
      url,
      data,
    })

    return response.data
  } catch (error) {
    error.message = "Internal Server Error"
    error.statusCode = 500

    if (error.response) {
      switch (error.response.status) {
        case 400:
          error.statusCode = error.response.status
          error.message = error.response.data.message
          break
        case 401:
          if (error.response.data.message === "SessionExpired") {
            localStorage.clear()
          }
          error.statusCode = error.response.status
          error.message = "Unauthorized"
          break
        case 500:
          error.statusCode = error.response.status
          error.message = error.response.data.message
          break
        default:
          error.statusCode = error.response.status
          error.message = error.response.statusText
          break
      }
    }

    throw error
  }
}

export default fetcher
