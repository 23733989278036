import CardCategories from "@components/shared-components/Card/CardCategoriesFilter"
import Icon from "lib/components/Icons"
import ContainerModalCustom from "../ModalContainer"

interface Props {
  onClose: () => void
  isOpen: boolean
  onSelectCategory: (categorySlug: string) => void
}
function ModalFilterCategories({
  onClose,
  isOpen,
  onSelectCategory,
}: Props) {
  return (
    <ContainerModalCustom closeModal={onClose} isModalOpen={isOpen}>
      <div className="border w-screen h-screen">
        {isOpen && (
          <style>{`
            .qcw-trigger-btn {
              display: none !important;
            }
          `}</style>
        )}
        <div className="fixed top-4 left-4 w-6 h-6 z-40">
          <Icon
            iconName="arrowLeftBack"
            color="black"
            styles="hover:cursor-pointer"
            onClick={onClose}
          />
        </div>
        <CardCategories
          onSelectCategory={onSelectCategory}
        />
      </div>
    </ContainerModalCustom>
  )
}

export default ModalFilterCategories
